import './App.css';
import { useNavigate,Router,Route } from 'react-router-dom';
import { Table, Row, Col, ListGroup, Image, Form, Button, Card,DropdownButton,Dropdown,Container,Alert } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import React,{useState, useEffect} from "react"
import jwt_decode from 'jwt-decode';





function Home() {



  const [isAuthorized, setIsAuthorized] = useState(false);
    const [checkGroup, setCheckGroup] = useState(null);
    useEffect(() => {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwt_decode(token);
        
        const { permission, group } = decodedToken;

        
        if (permission.includes('normal') || permission.includes('admin')) {
          setIsAuthorized(true);
          setCheckGroup(group);
          
        }
        
      }
    }, []);



  const navigate = useNavigate();
  // localStorage.removeItem('yearTitle');
  // localStorage.removeItem('quarterTitle');
  // localStorage.removeItem('monthTitle');
  // localStorage.removeItem('reportTitle');
  const group_id = localStorage.getItem('form_group_id');
  const [url, setUrl ] = useState("/selectTypeScreen/"+group_id);

  function handleBack(e) {
    // console.log('要回上一頁囉')
  }

  return (
    <Container>
    <div className="App">
      {isAuthorized? navigate(url) : navigate('/login')}
      {/* <h>首頁</h> */}
      {/* {<Button ><Link to= "/login" className="nav-link" size = "sm" onClick={()=>handleBack()}>
        登入
      </Link>
      </Button>
      } 
      {<Button ><Link to= {url} className="nav-link" size = "sm" onClick={()=>handleBack()}>
        上傳與填寫資料
      </Link>
      </Button>
      }  */}
    </div>
    </Container>
  );
}

export default Home;

import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <div className="bg-light mt-5">
      <Container>
        <Row>
          <Col md={4} className="text-center text-md-start">
            <p className="mb-md-0">KSLAB</p>
          </Col>
          <Col md={4} className="text-center">
            <p className="mb-md-0">© 2023, NCU Knowledge System Lab</p>
          </Col>
          <Col md={4} className="text-center text-md-end">
            <a href="https://www.ncuksl.com" target="_blank" rel="noreferrer">
            <p className="mb-md-0">聯絡我們</p>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Footer

import { Navbar,Nav,NavDropdown,Container} from "react-bootstrap";
import { BrowserRouter, Routes, Route, Link, NavLink} from "react-router-dom";
import { FaUser, FaFileUpload, FaDownload } from "react-icons/fa";
import logo from '../image/cloud-logo.png'
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
function Header(){
  const [isLogged, setIsLogged] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const groupList = { '1': '因材網', '2': 'edu磨課師+', '3': '教育大市集', '4':'教育百科', 
                      '5': '教育媒體影音', '6':'教育雲入口網' , '7':'IDC/OpenID', '8':'數位學伴', '9':'數位學習入口網', '10':'教育雲一般帳號'}  

  const [group, setGroup] = useState("")
  const [groupId, setGroupId] = useState("")
  const location = useLocation();
  

  useEffect(() => {
   
    const storedGroupId = localStorage.getItem("group_id");
    setGroupId(storedGroupId)
    if (storedGroupId) {
      setGroup(groupList[storedGroupId]);
      setIsLogged(true);
    }
    
    if(storedGroupId && storedGroupId ==="0"){
      setIsAdmin(true);
      setIsLogged(true);
      setGroup("管理員");
    }
  }, [location]);
  

  
  function handleLogIn() {
   
   
    const storedGroupId = localStorage.getItem("group_id");
  if (storedGroupId && groupList[storedGroupId] !== group) {
    setGroup(groupList[storedGroupId]);
    setIsLogged(true)
   
    }

  
    
  }
  function handleLogOut() {
    
    setIsAdmin(false);
    setGroup("");
    localStorage.clear();
    setIsLogged(false)
  }


  
    return(
        <Navbar bg="light" expand="md">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img src={logo} alt="logo" height="30" />
              
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
            <Link to ={`/home`} className="nav-link">
                首頁
             </Link>
            {!isAdmin && 
              <Link to ={`/selectTypeScreen/${groupId}`} className="nav-link">
                上傳與填寫資料
              </Link>
            }
            {isAdmin &&(<>
              <Link to="/group" className="nav-link" >
                單位列表
              </Link>

              <Link to="/dashboard" className="nav-link" >
                總表
              </Link>

              {/* <Link to="/download" className="nav-link">
                檔案管理 <FaDownload />
              </Link> */}
              
              </>
              )}
              
              {!isLogged &&(<><Link to="/login" className="nav-link" onClick={()=>handleLogIn()}>
                <FaUser /> 登入
              </Link>
              </>)}
              
              {isLogged && group && (<><Link to="/logout" className="nav-link" onClick={() => handleLogOut()}>
                <FaUser /> {group} | 登出
              </Link>
              </>)}
              
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    )
}
export default Header
import './App.css';
import { BrowserRouter, Routes, Route, Link} from "react-router-dom";
import Login from './screens/LoginScreen';
import React from 'react';
import SelectTypeScreen from './screens/SelectTypeScreen';
import GroupScreen from './screens/GroupScreen';
import FormScreen from './screens/FormScreen';
import Image from 'react-bootstrap/Image'
import logo from './image/cloud-logo.png'
// import DownloadScreen from './screens/Dashboard';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './Home'
import Dashboard from './screens/DashBoardScreen';

function App() {
  
  return (
    <BrowserRouter>
      <div>
      
       <Header/>

            <Routes>
                <Route element={<Home />} path={'/home'}></Route>
                <Route element={<Login />} path={'/login'}></Route>
                <Route element={<SelectTypeScreen />} path={'/selectTypeScreen/:group_id'}></Route>
                <Route element={<GroupScreen />} path={'/group'}></Route>
                <Route element={<Dashboard />} path={'/dashboard'}></Route>
                <Route element={<FormScreen />} path={'/form'}></Route>
                {/* <Route element={<Dashboard/>} path={'/dashboard'}></Route> */}
                {/* <Route element={<FormScreen />} path={'/form/:group_id'}></Route> */}
                {/* <Route element={<Yearly />} path={'/link'}></Route>
                <Route element={<Quarter_Month_Report_Button />} path={'/SystemLink/*'}></Route>
                <Route element={<Quarterly_Button />} path={'/quarterly'}></Route>
                <Route element={<Monthly_Button />} path={'/monthly'}></Route>                
                <Route element={<Upload />} path={'Upload'}></Route>            
                <Route element={<FormScreen />} path={'/form'}></Route> */}
                

            </Routes>
        
        <Image 
        className='center'
        src={logo}
        rounded d-block mx-auto
        />
        <Footer style={{ position: "fixed", bottom: 0, left: 0, width: "100%" }}></Footer> 
      </div>
      </BrowserRouter>
  )
}


export default App;

import React from 'react';
import Group from '../components/Group'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'; 
import Loader from '../components/Loader'
import Message from '../components/Message';
function GroupScreen({ match, location, history}) {
    const isAdmin = localStorage.getItem("group_id") === '0' ? true: false;
    const navigate = useNavigate(); 

    const groupList = ['因材網', 'edu磨課師+', '教育大市集', '教育百科',  
                      '教育媒體影音', '教育雲入口網', 'IDC/OpenID', '數位學伴', '數位學習入口網', '教育雲一般帳號'] 

    //  const groupList = ['因材網', '磨課師平臺', '教育大市集', '教育百科',  
    //                   '教育媒體影音', '教育雲入口網', 'IDC', '數位學伴', '數位學習入口網', '教育雲一般帳號'] 
                      
    const { error, loading  } = groupList


    if ( isAdmin ) {
        return (
            <div  style={{ textAlign: 'center'}}>
                <h1>單位列表</h1>
                
                {loading ? <Loader/>
                : error ? <Message variant='danger'>{error}</Message>
                    : 
                    <Row>
                        {groupList.map(group => (
                            <Col key={group.id} sm={12} md={6} lg={4} xl={3} style={{ fontSize: '1.5rem'}}>
                                <Group group={group} />
                            </Col>
                            
                    ))}


                </Row>
            }             
            </div>
        )
    } // if 
    else{
        navigate("/home")
    }
    
}
export default GroupScreen

import React,{useState, useEffect} from "react"
import { useDispatch, useSelector} from 'react-redux'
import { Table, Row, Col, ListGroup, Image, Form, Button, Card,DropdownButton,Dropdown,Container,Alert } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { useNavigate,Router,Route } from 'react-router-dom';
import FormScreen from "./FormScreen";
import { useParams } from "react-router-dom";
import jwt_decode from 'jwt-decode';
function SelectTypeScreen({}) {

    const [isAuthorized, setIsAuthorized] = useState(false);
    const [checkGroup, setCheckGroup] = useState(null);
    useEffect(() => {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwt_decode(token);
        
        const { permission, group } = decodedToken;
        
        if (permission.includes('normal') || permission.includes('admin')) {
          setIsAuthorized(true);
          setCheckGroup(group);
        }
        
      }
    }, []);

    let {group_id} = useParams(); 
    const navigate = useNavigate();
    const [showForm,setShowForm] = useState(false)
    const [yearTitle,setYearTitle] = useState('請選擇年份')
    const [monthTitle,setMonthTitle] = useState('請選擇月份')
    const [quarterTitle,setQuarterTitle] = useState('請選擇Q1~Q4')
    const [reportTitle,setReportTitle] = useState('季報/月報')

    // const formData = {group_id, yearTitle, monthTitle, quarterTitle, reportTitle};
    // const redirect = '/selectType'

    // const yearList = [2023,2024,2025,2026,2027,2028,2029,2030];
    const yearList = [2023,2024,2025];
    const monthList = ["一月","二月","三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月"];
    // const monthList = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const quarterList = ["Q1", "Q2", "Q3", "Q4"];
    const reportList = ["季報","月報"];
    
    const [hasYear, setHasYear] = useState(false);
    localStorage.setItem('show_form', false );
    // const [hasReprtType, setHasReprtType] = useState(false);
    // const [hasReprtTime, setHasReprtTime] = useState(false);

    useEffect(() => {
        // This useEffect hook will run after formContent is updated
        // Add your code here to do something with the updated formContent
        // For example:
        // console.log("hasYear updated:", hasYear);

      }, [hasYear]);

    

    const handleSelectYear=(e)=>{
        
        var selected = e.split(',');
        localStorage.setItem("yearTitle", selected[0]);
        setYearTitle(selected[0]);
        setHasYear(true)
        // alert(e);
       
    }

    const handleSelectQuarter=(e)=>{ // select Q1-Q4
        
        var selected = e.split(',');
        localStorage.setItem("quarterTitle", selected[0]);
        setQuarterTitle(selected[0]);
        return <Alert>(e)</Alert>;
       
    }

    const handleSelectMonth=(e)=>{ // select Jan, Feb...
        
        var selected = e.split(',');
        localStorage.setItem("monthTitle", selected[0]);
        setMonthTitle(selected[0]);
        return <Alert>(e)</Alert>;
       
    }

    const handleSelectReport=(e)=>{ // select 季報/月報
        
        var selected = e.split(',');
        localStorage.setItem("reportTitle", selected[0]);
        setReportTitle(selected[0]);
        // alert(e);
       
    }
    const jumpToFormHandler =() =>{
        if(checkGroup !== "0" && checkGroup !== group_id){
            navigate('/selectTypeScreen/'+checkGroup);
        }
        else{
            if ( (hasYear && (reportTitle === "季報" && quarterTitle !== "請選擇Q1~Q4" ) ) || 
                 (hasYear && (reportTitle === "月報" && monthTitle !== "請選擇月份") ) ) {
                if(showForm){
                    setShowForm(false);
                }
                else{                    
                    setShowForm(true);
                    localStorage.setItem('form_group_id', group_id);
                    // navigate('/form/'+group_id);
                }

                
                setHasYear(false)
            }
            
        }
        
        // navigate('/form');
    }
    
    if(!isAuthorized){
        return (
            <Row style={{ textAlign:'center'  }} >
                 <small style={{ color: 'red' }}>請登入正確帳號</small>
            </Row>
        )
    }
    return (
        <Card style={{ width: '100%', marginTop: '20px', display: 'flex', alignItems: 'center'  }} >
        
        <Card.Header style={{ fontSize: '1.2rem', width: '100%', textAlign: 'center'}}> 請選擇報表資訊</Card.Header>
    
        <Card.Body className="text-center">
            <Card className="my-4 p-4 d-flex justify-content-center" style={{border: '2px solid #97CBFF', borderRadius: '10px'}}>
        
        <Container>
        
            <Row>
            {!showForm?
            <Col md = "auto" className="d-flex justify-content-center">
            
            <DropdownButton
            alignDown={true}
            title= {yearTitle || '年份'} 
            id="dropdown-menu-align-down"
            onSelect={handleSelectYear}
                >

            {yearList.map((year,index) =>{
            
            return <Dropdown.Item eventKey={year} key={index}>{year}</Dropdown.Item>
            })}
            </DropdownButton>
            
            </Col>
            :''}
            {!showForm?
            <Col md = "auto" className="d-flex justify-content-center">
            
            <DropdownButton
            alignDown={true}
            title= {reportTitle || '報表'}
            id="dropdown-menu-align-down"
            onSelect={handleSelectReport}
            >
            
            {reportList.map((report,index) =>{
            
            return <Dropdown.Item  eventKey={report}  key={index}>{report}</Dropdown.Item>
            })} 
            
            </DropdownButton>
            </Col>  
            :''}
            <>
            {reportTitle==='季報'?
            <>
            {/* 季報 */}
                <Col md = "auto" className="d-flex justify-content-center">
                {!showForm?
                <DropdownButton
                alignDown={true}
                title= {quarterTitle || '季度'}
                id="dropdown-menu-align-down"
                onSelect={handleSelectQuarter}
                >
                {quarterList.map((quarter,index) =>{
                
                return <Dropdown.Item  eventKey={quarter}  key={index}>{quarter}</Dropdown.Item>
                })}    
                </DropdownButton>
                :''}
                </Col>
            
            </>:  
            <>
            {/* 月報 */}
                
                <Col md = "auto" className="d-flex justify-content-center">
                {!showForm?
                <DropdownButton
                alignDown={true}
                title= {monthTitle || '月份'}
                id="dropdown-menu-align-down"
                onSelect={handleSelectMonth}
                >
                {monthList.map((month,index) =>{
                
                return <Dropdown.Item  eventKey={month}  key={index}>{month}</Dropdown.Item>
                })}    
                </DropdownButton>
                : ''}
                </Col>
                
            </>
            }
            </>
        
                
            
            <Col sm = "auto" className="d-flex justify-content-center">
            {!showForm?
            <Button variant="dark" onClick = {jumpToFormHandler} >{checkGroup !== "0" && checkGroup !== group_id?"即將重新導向正確畫面...":"進行"}</Button>
            :''}
            
            </Col>
            </Row>
            {/* {showForm && <Row> <FormScreen formData={formData}/></Row>} */}
            {/* {showForm ? navigate('/form/'+group_id) : "" } */}
            {showForm ? navigate('/form') : "" }
        </Container>
        
        </Card>
        </Card.Body>
        
        </Card>
        
        )
    
        
  }
  export default SelectTypeScreen



import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const groupList = { '因材網' : 1, 'edu磨課師+': 2, '教育大市集': 3, '教育百科': 4, 
                      '教育媒體影音':5 , '教育雲入口網':6 , 'IDC/OpenID': 7, '數位學伴':8, '數位學習入口網':9, '教育雲一般帳號':10 }


function Group({ group }) {
    const group_id = groupList[group];
    return (
       <Card className="my-3 p-3 rounded">
        
            <Card.Body>

                <Card.Text as="div">
                    <div className="my-3">
                        {/* {group.id} */}
                         
                        {/* <Link to ={`/selectTypeScreen/${group.id}`}>{group}</Link> 之後跳頁後改用這個模式 */}
                        {/*<Link to ={`/selectTypeScreen/`}>{group}</Link>*/}
                        <Link to ={`/selectTypeScreen/${group_id}`}>{group}</Link>
                        {/*<Link to ={`/selectTypeScreen/${group_id}`} state={{ "group_id": group_id }}>{group}</Link>*/}
                    </div>
                    
                </Card.Text>
                
               
                
            </Card.Body>
       </Card>
    )
}

export default Group

import React, { useState } from 'react';
// import { login } from "../components/apiUtil";
import { Row, Col, ListGroup, Image, Form, Button, Card, FormControl,FormLabel,Dropdown,DropdownButton,InputGroup } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import { login } from "../components/api";
import { useNavigate,Router,Route } from 'react-router-dom'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

const Login =() => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); 


  

  const handleLogin = (e) => {
    setLoading(true);
    setErrorMessage(null);
    login(username, password).then((data) => {
      if (data.message === "Wrong user name or password" || data.message === "請輸入正確的帳號、密碼" ) {
        
        setLoading(false);
        return setErrorMessage(data.message);
      }
      else if ( data.message === "successful login" ){
        setLoading(false);
        localStorage.setItem('group_id', data.group);

        if ( data.group === "0" ) navigate("/group/")
        else navigate("/selectTypeScreen/"+data.group)
        
      }

    });
 
    
    
  };

  const handleUsername = (e) => {
    setUsername(e.target.value);
  }; 

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };


  return(
    <Form>
  <div className='loginform'>
    <div  style={{ textAlign: 'center'}}>
      <h2 id='headerTitle'>登入</h2>
    </div>
    <div>
      <div style={{ textAlign: 'right'}}>
        <div >
        <Row>
          <Col xs={2}>
          <Form.Label htmlFor='username' className='account' >
            帳號
          </Form.Label>
          </Col>
          <Col xs={8}>
          <InputGroup>
            <FormControl
              id='username'
              type='text'
              placeholder='請輸入帳號'
              value={username}
              onChange={handleUsername}
            />
          </InputGroup>
          </Col>
          </Row> 
        </div>
        <div style={{ margin: '2% 0' }}>
        <Row>
          <Col xs={2}>
          <Form.Label htmlFor='password' className='account'>
            密碼
          </Form.Label>
          </Col>
          <Col xs={8}>
          <InputGroup>
            <FormControl
              id='password'
              type='password'
              placeholder='請輸入密碼'
              value={password}
              onChange={handlePassword}
            />
          </InputGroup>
          </Col>
          </Row> 
        </div>
        <div style={{textAlign: 'center'}}>
        {errorMessage && (
          <>
            <small style={{ color: 'red'  }}>{errorMessage}</small>
            <br />
          </>
        )}
        </div>
        <br />
        <div style={{ textAlign: 'center'}}>
          <Button
            className='login_button'
            variant='primary'
            disabled={loading}
            onClick={handleLogin}
          >
            {loading ? '登入中...' : '登入'}
            <FontAwesomeIcon icon={faSignInAlt} style={{ marginLeft: '10px' }} />
          </Button>
        </div>
        <ForgetPassword />
      </div>
    </div>
  </div>
</Form>
  )
}
export default Login    

function ForgetPassword() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div style={{ textAlign: 'center'}}>
      <Button variant="link" onClick={handleShow}>
        忘記密碼
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>忘記密碼</Modal.Title>
        </Modal.Header>
        <Modal.Body>請聯繫KSLAB專任助理
          實驗室: E6-B320 分機: 35353
          https://www.ncuksl.com</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            確定
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

// export function getAuth() {
//   return isAuth;
// }


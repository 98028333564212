import React, { useState, useEffect } from 'react'
import { Row, Col, ListGroup, Form, Button, Card, Container } from 'react-bootstrap'
import { Table } from 'react-bootstrap';
import { getDashboardData } from '../components/api';

// data =  {

//     'kpi1': { 
//         'total' : {'q1' : 10, 'q2':20, 'q3': 30, 'q4': 40}, 
//         'usage' : {'q1' : 10, 'q2':20, 'q3': 30, 'q4': 40},
//     }, 

// 	'kpi2':{},

// 	'kpi3':{},

// 	'kpi4':{}, 

// 	'kpi5':{},

// 	'other':{}

// }


function DashBoard() {
    // let date = new Date().toDateString(); // Mon Jun 19 2023
    //let year = new Date().getFullYear(); // 2023
    //let year = 2023;
    // console.log('year: ', year);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());


    const [dataDashboard, setDataDashboard] = useState({});
    const isAdmin = localStorage.getItem("group_id") === '0' ? true : false;


    useEffect(() => {
        async function fetchData() {

            //const dataFromBackend = await getDashboardDataYear(year);

            const dataFromBackend = await getDashboardData(currentYear);
            console.log(': ', dataFromBackend);
            setDataDashboard(dataFromBackend);
        }

        fetchData();

    }, [currentYear]);
    const handlePreviousYear = () => {
        const previousYear = currentYear - 1;
        setCurrentYear(previousYear);
    };
    const handleNextYear = () => {
        const NextYear = currentYear + 1;
        setCurrentYear(NextYear);
    };



    let kpi1_total_usage = 0;
    let kpi2_Q1 = 0, kpi2_Q2 = 0, kpi2_Q3 = 0, kpi2_Q4 = 0;
    let kpi3_Q1 = 0, kpi3_Q2 = 0, kpi3_Q3 = 0, kpi3_Q4 = 0, kpi3_resource1 = 0, kpi3_resource2 = 0;
    let kpi4_total = 0;
    let kpi5_Q1 = 0, kpi5_Q2 = 0, kpi5_Q3 = 0, kpi5_Q4 = 0, kpi5_resource1 = 0, kpi5_resource2 = 0, kpi5_resource3 = 0;
    let other_Q1 = 0, other_Q2 = 0, other_Q3 = 0, other_Q4 = 0;
    let other_resource1 = 0, other_resource2 = 0, other_resource3 = 0, other_resource4, other_resource5;


    if (isAdmin) {

        if (typeof (dataDashboard.kpi1) !== 'undefined') {


            kpi1_total_usage = Number(dataDashboard.kpi1.usage.Q1) + Number(dataDashboard.kpi1.usage.Q2) + Number(dataDashboard.kpi1.usage.Q3) + Number(dataDashboard.kpi1.usage.Q4);

            kpi2_Q1 = Number(dataDashboard.kpi2.source1.Q1) + Number(dataDashboard.kpi2.source2.Q1) + Number(dataDashboard.kpi2.source3.Q1) + Number(dataDashboard.kpi2.source4.Q1);
            kpi2_Q2 = Number(dataDashboard.kpi2.source1.Q2) + Number(dataDashboard.kpi2.source2.Q2) + Number(dataDashboard.kpi2.source3.Q2) + Number(dataDashboard.kpi2.source4.Q2);
            kpi2_Q3 = Number(dataDashboard.kpi2.source1.Q3) + Number(dataDashboard.kpi2.source2.Q3) + Number(dataDashboard.kpi2.source3.Q3) + Number(dataDashboard.kpi2.source4.Q3);
            kpi2_Q4 = Number(dataDashboard.kpi2.source1.Q4) + Number(dataDashboard.kpi2.source2.Q4) + Number(dataDashboard.kpi2.source3.Q4) + Number(dataDashboard.kpi2.source4.Q4);


            if (currentYear === 2023) { dataDashboard.kpi3.source1.Q1 = 259338; } // 應助理要求，從後台寫死 (只有這格)
            kpi3_Q1 = Number(dataDashboard.kpi3.source1.Q1) + Number(dataDashboard.kpi3.source2.Q1);
            kpi3_Q2 = Number(dataDashboard.kpi3.source1.Q2) + Number(dataDashboard.kpi3.source2.Q2);
            kpi3_Q3 = Number(dataDashboard.kpi3.source1.Q3) + Number(dataDashboard.kpi3.source2.Q3);
            kpi3_Q4 = Number(dataDashboard.kpi3.source1.Q4) + Number(dataDashboard.kpi3.source2.Q4);
            kpi3_resource1 = Number(dataDashboard.kpi3.source1.Q1) + Number(dataDashboard.kpi3.source1.Q2) + Number(dataDashboard.kpi3.source1.Q3) + Number(dataDashboard.kpi3.source1.Q4);
            kpi3_resource2 = Number(dataDashboard.kpi3.source2.Q1) + Number(dataDashboard.kpi3.source2.Q2) + Number(dataDashboard.kpi3.source2.Q3) + Number(dataDashboard.kpi3.source2.Q4);

            kpi4_total = Number(dataDashboard.kpi4.Q1) + Number(dataDashboard.kpi4.Q2) + Number(dataDashboard.kpi4.Q3) + Number(dataDashboard.kpi4.Q4)


            kpi5_Q1 = Number(dataDashboard.kpi5.source1.Q1) + Number(dataDashboard.kpi5.source2.Q1) + Number(dataDashboard.kpi5.source3.Q1);
            kpi5_Q2 = Number(dataDashboard.kpi5.source1.Q2) + Number(dataDashboard.kpi5.source2.Q2) + Number(dataDashboard.kpi5.source3.Q2);
            kpi5_Q3 = Number(dataDashboard.kpi5.source1.Q3) + Number(dataDashboard.kpi5.source2.Q3) + Number(dataDashboard.kpi5.source3.Q3);
            kpi5_Q4 = Number(dataDashboard.kpi5.source1.Q4) + Number(dataDashboard.kpi5.source2.Q4) + Number(dataDashboard.kpi5.source3.Q4);
            kpi5_resource1 = Number(dataDashboard.kpi5.source1.Q1) + Number(dataDashboard.kpi5.source1.Q2) + Number(dataDashboard.kpi5.source1.Q3) + Number(dataDashboard.kpi5.source1.Q4);
            kpi5_resource2 = Number(dataDashboard.kpi5.source2.Q1) + Number(dataDashboard.kpi5.source2.Q2) + Number(dataDashboard.kpi5.source2.Q3) + Number(dataDashboard.kpi5.source2.Q4);
            kpi5_resource3 = Number(dataDashboard.kpi5.source3.Q1) + Number(dataDashboard.kpi5.source3.Q2) + Number(dataDashboard.kpi5.source3.Q3) + Number(dataDashboard.kpi5.source3.Q4);


            other_Q1 = Number(dataDashboard.other.source1.Q1) + Number(dataDashboard.other.source2.Q1) + Number(dataDashboard.other.source3.Q1)
                + Number(dataDashboard.other.source4.Q1) + Number(dataDashboard.other.source5.Q1);
            other_Q2 = Number(dataDashboard.other.source1.Q2) + Number(dataDashboard.other.source2.Q2) + Number(dataDashboard.other.source3.Q2)
                + Number(dataDashboard.other.source4.Q2) + Number(dataDashboard.other.source5.Q2);
            other_Q3 = Number(dataDashboard.other.source1.Q3) + Number(dataDashboard.other.source2.Q3) + Number(dataDashboard.other.source3.Q3)
                + Number(dataDashboard.other.source4.Q3) + Number(dataDashboard.other.source5.Q3);
            other_Q4 = Number(dataDashboard.other.source1.Q4) + Number(dataDashboard.other.source2.Q4) + Number(dataDashboard.other.source3.Q4)
                + Number(dataDashboard.other.source4.Q4) + Number(dataDashboard.other.source5.Q4);

            other_resource1 = Number(dataDashboard.other.source1.Q1) + Number(dataDashboard.other.source1.Q2) + Number(dataDashboard.other.source1.Q3) + Number(dataDashboard.other.source1.Q4);
            other_resource2 = Number(dataDashboard.other.source2.Q1) + Number(dataDashboard.other.source2.Q2) + Number(dataDashboard.other.source2.Q3) + Number(dataDashboard.other.source2.Q4);
            other_resource3 = Number(dataDashboard.other.source3.Q1) + Number(dataDashboard.other.source3.Q2) + Number(dataDashboard.other.source3.Q3) + Number(dataDashboard.other.source3.Q4);
            other_resource4 = Number(dataDashboard.other.source4.Q1) + Number(dataDashboard.other.source4.Q2) + Number(dataDashboard.other.source4.Q3) + Number(dataDashboard.other.source4.Q4);
            other_resource5 = Number(dataDashboard.other.source5.Q1) + Number(dataDashboard.other.source5.Q2) + Number(dataDashboard.other.source5.Q3) + Number(dataDashboard.other.source5.Q4);

            // console.log('dataDashboard: ', dataDashboard);
            return (

                <div>
                    <div style={{ marginTop: '20px', textAlign: 'center', fontSize: 'Large', fontWeight: 'bold' }}>{currentYear}年總表</div>
                    {/* <Button onClick={handleClickYear(year)}>2023</Button> */}
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button onClick={handlePreviousYear} style={{ marginRight: '10px' }}>上一年</Button>
                        <Button onClick={handleNextYear}>下一年</Button>
                    </div>


                    {/* KPI【1】：教育體系單一簽入新增人次達475萬 */}
                    <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }}>
                        <Card.Body >
                            <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }} >
                                <Container>
                                    <Table striped bordered className="month-table">
                                        <thead>
                                            <tr>
                                                <th colspan="4">KPI【1】：教育體系單一簽入新增人次達475萬</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td >資料來源</td>
                                                <td colspan="3">
                                                    <div>OpenID介接總數與其使用人次</div>
                                                    <div>【備註】介接總數為3月、6月、9月與12月數值。</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td rowSpan="6">執行情形</td>
                                                <td></td>
                                                <td>
                                                    介接總數
                                                    <span style={{ color: 'red', fontSize: 'small' }}> *備註</span>
                                                </td>
                                                <td>使用人次</td>
                                            </tr>
                                            <tr>
                                                <td>Q1</td>
                                                {/* <td>{ dataDashboard.kpi1?.total.q3||"" }</td>  */}
                                                {/* dataDashboard.kpi1?.q1_total||"" */}
                                                <td>{dataDashboard.kpi1.total.Q1}</td>
                                                <td>{dataDashboard.kpi1.usage.Q1}</td>
                                            </tr>
                                            <tr>
                                                <td>Q2</td>
                                                <td>{dataDashboard.kpi1.total.Q2}</td>
                                                <td>{dataDashboard.kpi1.usage.Q2}</td>
                                            </tr>
                                            <tr>
                                                <td>Q3</td>
                                                <td>{dataDashboard.kpi1.total.Q3}</td>
                                                <td>{dataDashboard.kpi1.usage.Q3}</td>
                                            </tr>
                                            <tr>
                                                <td>Q4</td>
                                                <td>{dataDashboard.kpi1.total.Q4}</td>
                                                <td>{dataDashboard.kpi1.usage.Q4}</td>
                                            </tr>
                                            <tr>
                                                <td>總計</td>
                                                <td>-</td>
                                                <td>{kpi1_total_usage}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Container> </Card></Card.Body></Card>


                    {/* KPI【2】：瀏覽人次每年新增1,500萬人 */}
                    <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }}>
                        <Card.Body >
                            <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }} >
                                <Container>
                                    <Table striped bordered className="month-table">
                                        <thead>
                                            <tr>
                                                <th colSpan="7">KPI【2】：瀏覽人次每年新增1,500萬人</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td rolSpan="4">資料來源</td>
                                                <td colSpan="6">
                                                    <tr>1. 教育雲一般帳號登入人次（數位入口網、入口網、大市集、百科、影音、磨課師）</tr>
                                                    <tr>2. 因材網全部帳號登入人次（因材網帳號+教育雲一般會員帳號+教育雲端帳號（含OpenID））</tr>
                                                    <tr>3. 教育雲端帳號（含OpenID）登入教育雲主要服務之人次（數位入口網、入口網、大市集、百科、影音、磨課師、校園電子郵件）</tr>
                                                    <tr>4. 各子網未登入人次（大市集、百科、影音、數位學習入口網、入口網）</tr>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td rowSpan="6">執行情形</td>
                                                <td></td>
                                                <td>資料來源1</td>
                                                <td>資料來源2</td>
                                                <td>資料來源3</td>
                                                <td>資料來源4</td>
                                                <td>小計</td>
                                            </tr>

                                            <tr>
                                                <td>Q1</td>
                                                <td>{dataDashboard.kpi2.source1.Q1}</td>
                                                <td>{dataDashboard.kpi2.source2.Q1}</td>
                                                <td>{dataDashboard.kpi2.source3.Q1}</td>
                                                <td>{dataDashboard.kpi2.source4.Q1}</td>
                                                <td>{kpi2_Q1}</td>
                                            </tr>


                                            <tr>
                                                <td>Q2</td>
                                                <td>{dataDashboard.kpi2.source1.Q2}</td>
                                                <td>{dataDashboard.kpi2.source2.Q2}</td>
                                                <td>{dataDashboard.kpi2.source3.Q2}</td>
                                                <td>{dataDashboard.kpi2.source4.Q2}</td>
                                                <td>{kpi2_Q2}</td>
                                            </tr>

                                            <tr>
                                                <td>Q3</td>
                                                <td>{dataDashboard.kpi2.source1.Q3}</td>
                                                <td>{dataDashboard.kpi2.source2.Q3}</td>
                                                <td>{dataDashboard.kpi2.source3.Q3}</td>
                                                <td>{dataDashboard.kpi2.source4.Q3}</td>
                                                <td>{kpi2_Q3}</td>
                                            </tr>

                                            <tr>
                                                <td>Q4</td>
                                                <td>{dataDashboard.kpi2.source1.Q4}</td>
                                                <td>{dataDashboard.kpi2.source2.Q4}</td>
                                                <td>{dataDashboard.kpi2.source3.Q4}</td>
                                                <td>{dataDashboard.kpi2.source4.Q4}</td>
                                                <td>{kpi2_Q4}</td>
                                            </tr>

                                            <tr>
                                                <td>總計</td>
                                                <td colspan="5" align='center'>{kpi2_Q1 + kpi2_Q2 + kpi2_Q3 + kpi2_Q4}</td>
                                            </tr>


                                        </tbody>
                                    </Table>
                                </Container> </Card></Card.Body></Card>


                    {/* KPI【3】：師生自主學習與終身學習達50萬人 */}
                    <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }}>
                        <Card.Body >
                            <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }} >
                                <Container>
                                    <Table striped bordered className="month-table">
                                        <thead>
                                            <tr>
                                                <th colSpan="5">KPI【3】：師生自主學習與終身學習達50萬人</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td rolSpan="2">資料來源</td>
                                                <td colSpan="4">
                                                    <tr>1.edu磨課師+：教師帳號登入人數</tr>
                                                    <tr>2.因材網：學生全部帳號登入人數（因材網帳號+教育雲一般會員帳號+教育雲端帳號（含OpenID））</tr>
                                                </td>
                                            </tr>


                                            <tr>
                                                <td rowSpan="6">執行情形</td>
                                                <td></td>
                                                <td>資料來源1</td>
                                                <td>資料來源2</td>
                                                <td>小計</td>
                                            </tr>

                                            <tr>
                                                <td>Q1</td>
                                                <td>{dataDashboard.kpi3.source1.Q1}</td>
                                                <td>{dataDashboard.kpi3.source2.Q1}</td>
                                                <td>{kpi3_Q1}</td>
                                            </tr>


                                            <tr>
                                                <td>Q2</td>
                                                <td>{dataDashboard.kpi3.source1.Q2}</td>
                                                <td>{dataDashboard.kpi3.source2.Q2}</td>
                                                <td>{kpi3_Q2}</td>
                                            </tr>

                                            <tr>
                                                <td>Q3</td>
                                                <td>{dataDashboard.kpi3.source1.Q3}</td>
                                                <td>{dataDashboard.kpi3.source2.Q3}</td>
                                                <td>{kpi3_Q3}</td>
                                            </tr>

                                            <tr>
                                                <td>Q4</td>
                                                <td>{dataDashboard.kpi3.source1.Q4}</td>
                                                <td>{dataDashboard.kpi3.source2.Q4}</td>
                                                <td>{kpi3_Q4}</td>
                                            </tr>

                                            <tr>
                                                <td>總計</td>
                                                <td>{kpi3_resource1}</td>
                                                <td>{kpi3_resource2}</td>
                                                <td>{kpi3_resource1 + kpi3_resource2}</td>
                                            </tr>


                                        </tbody>
                                    </Table>
                                </Container> </Card></Card.Body></Card>


                    {/*KPI【4】：支援學生適性學習達16萬人 */}
                    <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }}>
                        <Card.Body >
                            <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }} >
                                <Container>
                                    <Table striped bordered className="month-table">
                                        <thead>
                                            <tr>
                                                <th colSpan="3">KPI【4】：支援學生適性學習達16萬人</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>資料來源</td>
                                                <td colSpan="2">因材網：學生帳號登入人數（因材網帳號+教育雲一般會員帳號+教育雲端帳號（含OpenID））</td>
                                            </tr>


                                            <tr>
                                                <td rowSpan="6">執行情形</td>
                                                <td></td>
                                                <td>資料來源</td>
                                            </tr>

                                            <tr>
                                                <td>Q1</td>
                                                <td>{dataDashboard.kpi4.Q1}</td>
                                            </tr>


                                            <tr>
                                                <td>Q2</td>
                                                <td>{dataDashboard.kpi4.Q2}</td>
                                            </tr>

                                            <tr>
                                                <td>Q3</td>
                                                <td>{dataDashboard.kpi4.Q3}</td>
                                            </tr>

                                            <tr>
                                                <td>Q4</td>
                                                <td>{dataDashboard.kpi4.Q4}</td>
                                            </tr>

                                            <tr>
                                                <td>總計</td>
                                                <td>{kpi4_total}</td>
                                            </tr>


                                        </tbody>
                                    </Table>
                                </Container> </Card></Card.Body></Card>

                    {/*KPI【5】：資源被下載或引用次數達190萬次 */}
                    <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }}>
                        <Card.Body >
                            <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }} >
                                <Container>
                                    <Table striped bordered className="month-table">
                                        <thead>
                                            <tr>
                                                <th colSpan="7">KPI【5】：資源被下載或引用次數達190萬次</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td rolSpan="3">資料來源</td>
                                                <td colSpan="6">
                                                    <tr>1.教育大市集，引用、分享與下載次數</tr>
                                                    <tr>2.教育媒體影音，引用、分享與觀看次數</tr>
                                                    <tr>3.教育百科，引用與轉寄次數</tr>
                                                </td>
                                            </tr>


                                            <tr>
                                                <td rowSpan="6">執行情形</td>
                                                <td></td>
                                                <td>資料來源1</td>
                                                <td>資料來源2</td>
                                                <td>資料來源3</td>
                                                <td>小計</td>
                                            </tr>

                                            <tr>
                                                <td>Q1</td>
                                                <td>{dataDashboard.kpi5.source1.Q1}</td>
                                                <td>{dataDashboard.kpi5.source2.Q1}</td>
                                                <td>{dataDashboard.kpi5.source3.Q1}</td>
                                                <td>{kpi5_Q1}</td>
                                            </tr>


                                            <tr>
                                                <td>Q2</td>
                                                <td>{dataDashboard.kpi5.source1.Q2}</td>
                                                <td>{dataDashboard.kpi5.source2.Q2}</td>
                                                <td>{dataDashboard.kpi5.source3.Q2}</td>
                                                <td>{kpi5_Q2}</td>
                                            </tr>

                                            <tr>
                                                <td>Q3</td>
                                                <td>{dataDashboard.kpi5.source1.Q3}</td>
                                                <td>{dataDashboard.kpi5.source2.Q3}</td>
                                                <td>{dataDashboard.kpi5.source3.Q3}</td>
                                                <td>{kpi5_Q3}</td>
                                            </tr>

                                            <tr>
                                                <td>Q4</td>
                                                <td>{dataDashboard.kpi5.source1.Q4}</td>
                                                <td>{dataDashboard.kpi5.source2.Q4}</td>
                                                <td>{dataDashboard.kpi5.source3.Q4}</td>
                                                <td>{kpi5_Q4}</td>
                                            </tr>

                                            <tr>
                                                <td>總計</td>
                                                <td>{kpi5_resource1}</td>
                                                <td>{kpi5_resource2}</td>
                                                <td>{kpi5_resource3}</td>
                                                <td>{kpi5_resource1 + kpi5_resource2 + kpi5_resource3}</td>
                                            </tr>


                                        </tbody>
                                    </Table>
                                </Container> </Card></Card.Body></Card>


                    {/*其他：平臺數位資源數 */}
                    <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }}>
                        <Card.Body >
                            <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }} >
                                <Container>
                                    <Table striped bordered className="month-table">
                                        <thead>
                                            <tr>
                                                <th colSpan="8">其他：平臺數位資源數</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td rolSpan="5">資料來源</td>
                                                <td colSpan="7">
                                                    <tr>1.因材網：教學影片數、測驗試題與教學元件</tr>
                                                    <tr>2.教育大市集：APP、WEB與E-BOOK數</tr>
                                                    <tr>3.教育百科：詞條數</tr>
                                                    <tr>4.教育媒體影音：影片數</tr>
                                                    <tr>5.edu磨課師+：課程數</tr>
                                                    <tr>【備註】資料為3月、6月、9月與12月數值。</tr>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td rowSpan="6">執行情形</td>
                                                <td></td>
                                                <td>資料來源1<span style={{ color: 'red', fontSize: 'small' }}> *備註</span> </td>
                                                <td>資料來源2<span style={{ color: 'red', fontSize: 'small' }}> *備註</span> </td>
                                                <td>資料來源3<span style={{ color: 'red', fontSize: 'small' }}> *備註</span> </td>
                                                <td>資料來源4<span style={{ color: 'red', fontSize: 'small' }}> *備註</span> </td>
                                                <td>資料來源5<span style={{ color: 'red', fontSize: 'small' }}> *備註</span> </td>
                                                <td>小計</td>
                                            </tr>

                                            <tr>
                                                <td>Q1</td>
                                                <td>{dataDashboard.other.source1.Q1}</td>
                                                <td>{dataDashboard.other.source2.Q1}</td>
                                                <td>{dataDashboard.other.source3.Q1}</td>
                                                <td>{dataDashboard.other.source4.Q1}</td>
                                                <td>{dataDashboard.other.source5.Q1}</td>
                                                <td>{other_Q1}</td>
                                            </tr>


                                            <tr>
                                                <td>Q2</td>
                                                <td>{dataDashboard.other.source1.Q2}</td>
                                                <td>{dataDashboard.other.source2.Q2}</td>
                                                <td>{dataDashboard.other.source3.Q2}</td>
                                                <td>{dataDashboard.other.source4.Q2}</td>
                                                <td>{dataDashboard.other.source5.Q2}</td>
                                                <td>{other_Q2}</td>
                                            </tr>

                                            <tr>
                                                <td>Q3</td>
                                                <td>{dataDashboard.other.source1.Q3}</td>
                                                <td>{dataDashboard.other.source2.Q3}</td>
                                                <td>{dataDashboard.other.source3.Q3}</td>
                                                <td>{dataDashboard.other.source4.Q3}</td>
                                                <td>{dataDashboard.other.source5.Q3}</td>
                                                <td>{other_Q3}</td>
                                            </tr>

                                            <tr>
                                                <td>Q4</td>
                                                <td>{dataDashboard.other.source1.Q4}</td>
                                                <td>{dataDashboard.other.source2.Q4}</td>
                                                <td>{dataDashboard.other.source3.Q4}</td>
                                                <td>{dataDashboard.other.source4.Q4}</td>
                                                <td>{dataDashboard.other.source5.Q4}</td>
                                                <td>{other_Q4}</td>
                                            </tr>                                            

                                        </tbody>
                                    </Table>
                                </Container> </Card></Card.Body></Card>

                </div>
            )
        }

        else {
            return (
                <div style={{ color: 'red', textAlign: 'center', fontSize: 'Large', fontWeight: 'bold' }}>總表載入中，請稍後</div>
            )
        }
    } // if 
    else {
        return (
            <div style={{ color: 'red', textAlign: 'center', fontSize: 'Large', fontWeight: 'bold' }}>權限不足，請登入</div>
        )
    } // else 

}
export default DashBoard
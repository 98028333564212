import React, { useState, useEffect } from 'react'
import { useNavigate, Router, Route } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { Row, Col, ListGroup, Image, Form, Button, Card, FormControl, FormLabel, Dropdown, DropdownButton, Container } from 'react-bootstrap'
import { postFormContent, getFormContent } from "../components/api";
import { Table } from 'react-bootstrap';
import { useParams } from "react-router-dom";

function FormScreen({ match, location, history, formData }) {
    const navigate = useNavigate();
    // const { group_id, yearTitle, monthTitle, quarterTitle, reportTitle } = formData;
    // const [name, setName] = useState('')
    // const [result, setResult] = useState('')
    // const [benefit, setBenefit] = useState('')
    // const [prospect, setProspect] = useState('')
    // const [followUp, setFollowUp] = useState('')
    const [loginId, setLoginId] = useState(localStorage.getItem('group_id'));
    const [group_id, setGroupID] = useState(localStorage.getItem('form_group_id'));
    const [yearTitle, setYearTitle] = useState(localStorage.getItem('yearTitle'));
    const [monthTitle, setMonthTitle] = useState(localStorage.getItem('monthTitle'));
    const [quarterTitle, setQuarterTitle] = useState(localStorage.getItem('quarterTitle'));
    const [reportTitle, setReportTitle] = useState(localStorage.getItem('reportTitle'));
    // const monthTitle = localStorage.getItem('monthTitle');
    // const quarterTitle = localStorage.getItem('quarterTitle');
    // const reportTitle = localStorage.getItem('reportTitle');

    const [file, setFile] = useState(null);
    const [downloadFile, setDownloadFile] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);


    const [isQuarterlyReport, setIsQuarterlyReport] = useState(reportTitle === '季報' ? true : false)

    const groupList = {
        1: '因材網', 2: 'edu磨課師+', 3: '教育大市集', 4: '教育百科',
        5: '教育媒體影音', 6: '教育雲入口網', 7: 'IDC/OpenID', 8: '數位學伴', 9: '數位學習入口網', 10: '教育雲一般帳號'
    }
    const [groupName, setGroupName] = useState(groupList[group_id] ? groupList[group_id] : "");
    const [formContent, setFormContent] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingTable2, setIsEditingTable2] = useState(false); // 僅IDC有
    const [canEdit, setCanEdit] = useState(false);

    const [loading, setLoading] = useState(false);
    const [show_form, setShow_Form] = useState(false);

    function RestrictNum(value) {
        /*
        限制只能輸入數字
        使用正則表達式
        */
        // const newVal = value.replace(/[^\d]+/, ''); // 只能輸入數字
        // const newVal = value.replace(/[^\d+(,\d\d\d)*.\d]+$/g, ''); // 只能輸入數字, 小數點, 逗號
        const newVal = value.replace(/[^\d+(\d)*.\d]+$/g, ''); // 只能輸入數字, 小數點
        // const newVal = value.replace(/[^\d+(,\d\d\d)*.?\d]+$/g, ''); // 只能輸入數字, 小數點, 逗號, 趴數
        // console.log(value)

        if (value.length === 1 && value === "0") { // 如果只有一個0 ->  直接return 
            return value;
        } // if 

        // const removeLeadZero = newVal.replace(/^0+/, ''); // 移除前導零
        // return removeLeadZero;
        return newVal;
    }

    useEffect(() => {
        async function fetchData() {

            const data = await getFormContent(group_id, yearTitle, monthTitle, quarterTitle, reportTitle);
            setFormContent(data);
            setIsLoaded(true);
            setCanEdit(true);
        }
        fetchData();


        setIsLoaded(true);

    }, [])
    useEffect(() => {
        // This useEffect hook will run after formContent is updated
        // Add your code here to do something with the updated formContent
        // For example:
        // console.log("formContent updated:", formContent);
    }, [formContent]);

    // useEffect(() => {
    //     if (formContent?.GA_analysis?.upload_area) {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(new Blob([formContent.GA_analysis.upload_area]));
    //       reader.onloadend = () => {
    //         setDownloadFile(reader.result);
    //       };
    //     }
    //   }, [formContent]) 


    useEffect(() => {
        if ((yearTitle !== "null" && reportTitle !== "null" && quarterTitle !== "null") ||
            (yearTitle !== "null" && reportTitle !== "null" && monthTitle !== "null")) {
            setShow_Form(true)
        } // if 
        else {
            setShow_Form(false)
        } // else 


    }, [yearTitle, quarterTitle, monthTitle, reportTitle]);//

    const handleEditClick = () => {

        setIsEditing(true);

    };

    const handleEditClickTable2 = () => {

        setIsEditingTable2(true);

    };

    const handleSaveClick = () => {
        // TODO
        const reader = new FileReader();
        if (file) {
            setLoading(true)
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const fileDataUrl = reader.result;
                const formDataWithFile = {
                    ...formContent,
                    GA_analysis: {
                        ...formContent.GA_analysis,
                        upload_area: fileDataUrl,
                    },
                };

                setFormContent(formDataWithFile);

                // TODO: 
                let year = parseInt(yearTitle)

                postFormContent(group_id, formDataWithFile).then((data) => {
                    setLoading(false)
                });



                // window.location.href = `/selectTypeScreen/${group_id}`;
                // return null;
            };
        }
        else {
            const formDataNoData = {
                ...formContent
            }
            setFormContent(formDataNoData);
            // TODO: 
            let year = parseInt(yearTitle)

            postFormContent(group_id, formDataNoData).then((data) => {

            });


        }

        setIsEditing(false);

    };

    const handleSaveClickTable2 = () => {


        const formDataNoData = {
            ...formContent
        }
        setFormContent(formDataNoData);

        let year = parseInt(yearTitle)

        postFormContent(group_id, formDataNoData).then((data) => {

        });


        setIsEditingTable2(false);

    };

    function quartlyTable() { // 因為所有平台的季報都相同，所以拉出來寫
        return (
            <>
                <h3 className="text-primary" style={{ textAlign: 'left' }}> {groupName} </h3>
                <h5 style={{ textAlign: 'center' }} >{yearTitle} 年 {quarterTitle} {reportTitle}資料</h5>
                <h5 style={{ fontWeight: "bold" }}>服務建置/優化情形</h5>
                <h6>說明1：服務建置/優化情形為質化說明。</h6>
                <h6>說明2：填寫月份為4月、7月、10月與12月，皆與月報一併填寫，分別依序填寫「1~3月」、「4~6月」、「7~9月」與「10~12月」執行情形。</h6>
                <div style={{ textAlign: 'center' }} >
                    <Button onClick={handleEditClick} disabled={isEditing} class="btn btn-secondary">編輯</Button>
                </div>
                <Table striped bordered className="month-table">
                    <thead>
                        <tr>
                            <th>面項</th>
                            <th>填寫內容說明</th>
                            <th>樣態</th>
                            <th>執行情形</th>
                        </tr>
                    </thead>
                    <tbody>

                        {/*【服務建置/優化情形】*/}
                        <tr>
                            <td style={{ width: '150px' }}>
                                <div>【服務建置/優化情形】</div>
                                <div>本期計畫重要工作項目建置或優化內容與進度，及其預期效益或成果。</div>
                            </td>
                            <td style={{ width: '280px' }}>
                                <div>擇1至2項本期計畫最重要的工作項目撰寫。內容請具體說明：</div>
                                <div>1.為什麼重要？</div>
                                <div>2.目的是什麼？</div>
                                <div>3.其效益或成果為何？</div>
                                <div>4.目前執行進度說明。</div>
                                <br></br>
                                <div>範例1：影片時間軸預計新增預覽畫面，教師可藉由其功能，快速跳轉至重要段落並播放，使教學活動更加流暢。目前進度40%，預計9月正式提供該項功能服務；</div>
                                <br></br>
                                <div>範例2：完善資源盤點機制，訂定定期檢討及調整事項，並要求加盟單位強化對資源上架的內容審查，使平臺資源「質」與「量」同步提升。每年6月與12月盤點各式資源使用情形，針對使用次數較低的資源，進一步檢查、修正其內容與關鍵字，以提高後設資料之正確性。藉由前述強化動作，並同步提高模糊與容錯搜尋，以及預測查詢字串，促使搜尋引擎功能最佳化。截至上半年，已針對520筆資源進行修正。本司將會持續追蹤，倘若下半年使用率仍未明顯提升，即會列入下架評估，必要時須釋出儲存空間，以提高搜尋之效率。</div>
                                <br></br>
                                <div>範例3：新增「輸入法整合器」手寫識別功能，遇到不會念的字或平常都念錯的字，可透過手寫輸入方式，進而點選進入百科，獲得最正確的讀音。目前為規劃階段，預計6月於諮詢委員會議提出說明。</div>
                            </td>
                            <td style={{ width: '50px' }}>質化</td>
                            <td style={{ width: '300px' }}>
                                {isEditing ? (<FormControl
                                    as="textarea"
                                    rows={24}
                                    value={formContent.execution_state}
                                    onChange={(e) => {
                                        setFormContent({
                                            ...formContent,
                                            execution_state: e.target.value

                                        })
                                    }}
                                    required
                                />) :
                                    (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.execution_state ? formContent.execution_state : ""}</div>)
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>
                {isEditing && (
                    <div style={{ textAlign: 'center' }} >
                        <Button onClick={() => handleSaveClick()}>儲存</Button>
                    </div>
                )}
            </>
        )
    }

    //   function dataURItoBlob(dataURI) {
    //     const [dataHeader, dataBody] = dataURI.split(',');
    //     const byteString = atob(dataBody);
    //     const mimeString = dataHeader.split(':')[1].split(';')[0];
    //     const ab = new ArrayBuffer(byteString.length);
    //     const ia = new Uint8Array(ab);
    //     for (let i = 0; i < byteString.length; i++) {
    //       ia[i] = byteString.charCodeAt(i);
    //     }
    //     return new Blob([ab], { type: mimeString });
    //   }


    //     function handleChange(e) {
    //       setDownloadFile(URL.createObjectURL(e.target.files[0]));
    //       setFile(e.target.files[0]);
    //   }




    function handleBack(e) {
        const url = "/selectTypeScreen/" + group_id;
        localStorage.setItem('yearTitle', null);
        localStorage.setItem('quarterTitle', null);
        localStorage.setItem('monthTitle', null);
        localStorage.setItem('reportTitle', null);
        setYearTitle(null);
        setMonthTitle(null);
        setQuarterTitle(null);
        setReportTitle(null);
        navigate(url)
    }



    //  因材網 edu_ADL
    if (group_id === '1') {
        // 教師人次小計
        let total_teacher_times = 0;
        // 學生人次小計
        let total_std_times = 0;
        let total_teacher_count = 0;
        // 學生人數小計
        let total_std_count = 0;

        //console.log('從後端收到的資料: ', formContent)

        if (typeof formContent.monthly_usage !== "undefined") {
            total_teacher_times = Number(formContent.monthly_usage.ADL_teacher_login_times) + Number(formContent.monthly_usage.openID_teacher_login_times) + Number(formContent.monthly_usage.edu_teacher_login_times);
            total_std_times = Number(formContent.monthly_usage.ADL_student_login_times) + Number(formContent.monthly_usage.openID_student_login_times) + Number(formContent.monthly_usage.edu_student_login_times);
            total_teacher_count = Number(formContent.monthly_usage.ADL_teacher_login_count) + Number(formContent.monthly_usage.openID_teacher_login_count) + Number(formContent.monthly_usage.edu_teacher_login_count);
            total_std_count = Number(formContent.monthly_usage.ADL_student_login_count) + Number(formContent.monthly_usage.openID_student_login_count) + Number(formContent.monthly_usage.edu_student_login_count);

        } // if 



        // 資源小計
        let total_resource = 0;
        if (typeof formContent.platform_resource !== "undefined") {
            total_resource = Number(formContent.platform_resource.video_count) + Number(formContent.platform_resource.test_problem_count) + Number(formContent.platform_resource.interactive_element_count) + Number(formContent.platform_resource.dynamic_scoring_element_count);
        } // if 

        if (isLoaded) {
            return (
                // <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center'  }} > 
                // <Card.Body>
                // <Card className="my-4 p-4 d-flex justify-content-center" style={{border: '2px solid #F7F7F7', borderRadius: '10px'}}>
                <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }}>
                    <Card.Body >
                        <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }} >
                            <Container>
                                {loading && !isEditing ? < small style={{ color: 'red' }}>正在上傳...</small> : ''}
                                {!canEdit ? < small style={{ color: 'red' }}>載入中，請稍後...</small> : ''}
                                {<div class="d-grid gap-2 d-md-flex justify-content-md-end"><Button onClick={handleBack}>
                                    回上一頁
                                </Button>
                                </div>}
                                <>{formContent && (
                                    <>{isQuarterlyReport ?
                                        <>
                                            {/* 季報 */}
                                            {quartlyTable()}

                                        </>
                                        :
                                        <>
                                            {/* 月報 */}
                                            <h3 className="text-primary" style={{ textAlign: 'left' }}> {groupName} </h3>
                                            <h5 style={{ textAlign: 'center' }} >{yearTitle} 年 {monthTitle} {reportTitle}資料</h5>
                                            <h5 style={{ fontWeight: 'bold' }}>平臺使用情形</h5>
                                            <h6>說明1：平臺使用情形皆為量化數據。</h6>
                                            <h6>說明2：各式數據千分位數不逗號。</h6>
                                            <h6>說明3：注意單位（時分秒）換算。</h6>
                                            <div style={{ textAlign: 'center' }} >
                                                <Button onClick={handleEditClick} disabled={isEditing} class="btn btn-secondary">編輯</Button>
                                            </div>
                                            <Table striped bordered className="month-table" >
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '300px' }}>面項</th>
                                                        <th style={{ width: '500px' }} >填寫內容說明</th>
                                                        <th style={{ width: '100px' }}>樣態</th>
                                                        <th>執行情形</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {/*【使用面】該月平臺教學影片使用情形*/}
                                                    <tr>
                                                        <td rowSpan="2">
                                                            【使用面】 總註冊數
                                                        </td>
                                                        <td className="left-align">教師註冊數</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.account_counts.teacher_account_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        account_counts: {
                                                                            ...formContent.account_counts,
                                                                            teacher_account_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.account_counts?.teacher_account_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="left-align">學生註冊數</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.account_counts.student_account_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        account_counts: {
                                                                            ...formContent.account_counts,
                                                                            student_account_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.account_counts?.student_account_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【使用面】該月使用人次與人數 */}
                                                    <tr>
                                                        {/* <td rowSpan="16">【使用面】該月使用人次與人數</td> */}
                                                        <td rowSpan="16">
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            使用人次與人數
                                                        </td>

                                                        <td className="left">因材網帳號「教師登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.ADL_teacher_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            ADL_teacher_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.ADL_teacher_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">因材網帳號「學生登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.ADL_student_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            ADL_student_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.ADL_student_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">因材網帳號「教師登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.ADL_teacher_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            ADL_teacher_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.ADL_teacher_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">因材網帳號「學生登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.ADL_student_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            ADL_student_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.ADL_student_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">教育雲端帳號（含OpenID）「教師登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.openID_teacher_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            openID_teacher_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.openID_teacher_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">教育雲端帳號（含OpenID）「學生登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.openID_student_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            openID_student_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.openID_student_login_times || ""} </div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">教育雲端帳號（含OpenID）「教師登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.openID_teacher_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            openID_teacher_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.openID_teacher_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">教育雲端帳號（含OpenID）「學生登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.openID_student_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            openID_student_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.openID_student_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">教育雲一般帳號「教師登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_teacher_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_teacher_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_teacher_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">教育雲一般帳號「學生登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_student_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_student_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_student_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">教育雲一般帳號「教師登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_teacher_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_teacher_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_teacher_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">教育雲一般帳號「學生登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_student_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_student_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_student_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">教師人次小計</td>
                                                        <td>-</td>
                                                        <td>
                                                            {total_teacher_times}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">學生人次小計</td>
                                                        <td>-</td>
                                                        <td>{total_std_times}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">教師人數小計</td>
                                                        <td>-</td>
                                                        <td>{total_teacher_count}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">學生人數小計</td>
                                                        <td>-</td>
                                                        <td>{total_std_count}</td>
                                                    </tr>

                                                    {/*【使用面】使用學校總數 */}
                                                    <tr>
                                                        <td>
                                                            【使用面】使用學校
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>總數</span>
                                                        </td>
                                                        <td className="left-align"> 因材網使用學校總數（不含計畫/機關單位）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.school_counts.school_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        school_counts: {
                                                                            ...formContent.school_counts,
                                                                            school_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.school_counts?.school_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/*【使用面】該月平臺教學影片使用情形*/}
                                                    <tr>
                                                        <td rowSpan="2">
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺教學影片使用情形
                                                        </td>
                                                        <td className="left-align">教學影片觀看次數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.platform_video_usage.views_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        platform_video_usage: {
                                                                            ...formContent.platform_video_usage,
                                                                            views_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.platform_video_usage?.views_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="left-align">教學影片觀看時數（單位:時）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.platform_video_usage.views_time_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        platform_video_usage: {
                                                                            ...formContent.platform_video_usage,
                                                                            views_time_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.platform_video_usage?.views_time_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【資源面】平臺資源總數 */}
                                                    <tr>
                                                        <td rowSpan="5">
                                                            <div>
                                                                【資源面】平臺資源總數
                                                                <span style={{ color: 'red', fontWeight: 'bold' }}>總數</span>
                                                            </div>
                                                        </td>
                                                        <td className="left-align">教學影片總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.platform_resource.video_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        platform_resource: {
                                                                            ...formContent.platform_resource,
                                                                            video_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.platform_resource?.video_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">診斷測驗試題總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.platform_resource.test_problem_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        platform_resource: {
                                                                            ...formContent.platform_resource,
                                                                            test_problem_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.platform_resource?.test_problem_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">互動式教學元件總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.platform_resource.interactive_element_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        platform_resource: {
                                                                            ...formContent.platform_resource,
                                                                            interactive_element_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.platform_resource?.interactive_element_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">動態評量教學元件總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.platform_resource.dynamic_scoring_element_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        platform_resource: {
                                                                            ...formContent.platform_resource,
                                                                            dynamic_scoring_element_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.platform_resource?.dynamic_scoring_element_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">資源小計</td>
                                                        <td>-</td>
                                                        <td>{total_resource}</td>
                                                    </tr>

                                                    {/* 【推廣面】該月推廣活動辦理 */}
                                                    <tr>
                                                        <td rowSpan="2">
                                                            【推廣面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            推廣活動辦理
                                                        </td>
                                                        <td className="left-align">辦理場次</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.activity.events}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        activity: {
                                                                            ...formContent.activity,
                                                                            events: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.activity?.events || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">參與人次</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.activity.attend_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        activity: {
                                                                            ...formContent.activity,
                                                                            attend_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.activity?.attend_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【GA4分析】該月平臺使用情形 */}
                                                    <tr>
                                                        <td rowSpan="10">
                                                            【GA4分析】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺使用情形
                                                        </td>
                                                        <td className="left-align">使用者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.user}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            user: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.user || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">新使用者人數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.new_users}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            new_users: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.new_users || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">回訪者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.revisits}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            revisits: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.revisits || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-桌機desktop</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.desktops}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            desktops: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.desktops || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-行動裝置mobile</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.mobiles}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            mobiles: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.mobiles || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-平板電腦tablet</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.tablets}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            tablets: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.tablets || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">每位使用者互動工作階段</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.interactive_work_phase}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            interactive_work_phase: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.interactive_work_phase || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均單次工作階段參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.work_phase_average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            work_phase_average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.work_phase_average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">瀏覽</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.views}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            views: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.views || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                            {isEditing && (
                                                <div style={{ textAlign: 'center' }} >
                                                    <Button onClick={() => handleSaveClick()} >儲存</Button>
                                                </div>
                                            )}

                                        </>
                                    }
                                    </>
                                )
                                }</>

                            </Container>
                        </Card>
                    </Card.Body>
                </Card>
            )
        }
        else {
            navigate("/selectTypeScreen/" + group_id);
            // return (<h3>Loading</h3>)
        }

    }
    // edu磨課師+ / 磨課師平臺 edu_moocs
    else if (group_id === '2') {

        if (isLoaded) {

            return (
                <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }} >
                    <Card.Body>
                        <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }}>
                            <Container>
                                {loading && !isEditing ? <small style={{ color: 'red' }}>正在上傳...</small> : ''}
                                {!canEdit ? < small style={{ color: 'red' }}>載入中，請稍後...</small> : ''}
                                {<div class="d-grid gap-2 d-md-flex justify-content-md-end"><Button onClick={handleBack}>
                                    回上一頁
                                </Button>
                                </div>}
                                <>{formContent && (
                                    <>{isQuarterlyReport ?
                                        <>
                                            {/* 季報 */}
                                            {quartlyTable()}
                                        </>
                                        :
                                        <>
                                            {/* 月報 */}

                                            <h3 className="text-primary" style={{ textAlign: 'left' }}> {groupName} </h3>
                                            <h5 style={{ textAlign: 'center' }} >{yearTitle} 年 {monthTitle} {reportTitle}資料</h5>
                                            <h5 style={{ fontWeight: 'bold' }}>平臺使用情形</h5>
                                            <h6>說明1：平臺使用情形皆為量化數據。</h6>
                                            <h6>說明2：各式數據千分位數不逗號。</h6>
                                            <h6>說明3：注意單位（時分秒）換算。</h6>


                                            <div style={{ textAlign: 'center' }}>
                                                <Button onClick={handleEditClick} disabled={isEditing} class="btn btn-secondary">編輯</Button>
                                            </div>
                                            <Table striped bordered className="month-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '300px' }}>面項</th>
                                                        <th style={{ width: '500px' }}>填寫內容說明</th>
                                                        <th style={{ width: '100px' }}>樣態</th>
                                                        <th>執行情形</th>
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {/* 【使用面】該月使用人次與人數 */}
                                                    <tr>
                                                        <td rowSpan="2">
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            使用人次與人數</td>

                                                        <td className="left-align">教師使用人數</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                // type="text"
                                                                value={formContent.monthly_usage.teacher_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            teacher_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.teacher_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教師使用人次</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                // type="text"
                                                                value={formContent.monthly_usage.teacher_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            teacher_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.teacher_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【資源面】平臺資源總數 */}
                                                    <tr>
                                                        <td>
                                                            【資源面】平臺資源
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>總數</span>
                                                        </td>
                                                        <td className="left-align"> 課程總數 </td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.total_resource.total_courses}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        total_resource: {
                                                                            ...formContent.total_resource,
                                                                            total_courses: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.total_resource?.total_courses || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                    {/* 【資料儲存】計算期間：112年 */}
                                                    <tr>
                                                        <td rowSpan="2">【資料儲存】計算期間：{yearTitle - 1911}年</td>
                                                        <td className="left-align">該月上傳eduLRS筆數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_storage.eduLRS_month_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_storage: {
                                                                            ...formContent.resource_storage,
                                                                            eduLRS_month_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_storage?.eduLRS_month_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="left-align">累計上傳eduLRS筆數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_storage.eduLRS_total_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_storage: {
                                                                            ...formContent.resource_storage,
                                                                            eduLRS_total_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_storage?.eduLRS_total_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                    {/* 【GA4分析】該月平臺使用情形 */}
                                                    <tr>
                                                        <td rowSpan="10">
                                                            【GA4分析】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺使用情形
                                                        </td>
                                                        <td className="left-align">使用者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.user}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            user: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.user || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">新使用者人數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.new_users}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            new_users: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.new_users || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">回訪者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.revisits}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            revisits: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.revisits || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-桌機desktop</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.desktops}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            desktops: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.desktops || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-行動裝置mobile</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.mobiles}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            mobiles: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.mobiles || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-平板電腦tablet</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.tablets}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            tablets: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.tablets || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">每位使用者互動工作階段</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.interactive_work_phase}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            interactive_work_phase: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.interactive_work_phase || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均單次工作階段參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.work_phase_average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            work_phase_average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.work_phase_average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">瀏覽</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.views}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            views: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.views || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>


                                                </tbody>
                                            </Table>



                                            {isEditing && (
                                                <div style={{ textAlign: 'center' }} >
                                                    <Button onClick={() => handleSaveClick()}>儲存</Button>
                                                </div>
                                            )}

                                        </>
                                    }
                                    </>
                                )
                                }</>
                            </Container>
                        </Card>
                    </Card.Body>
                </Card>
            )
        }
        else {
            navigate("/selectTypeScreen/" + group_id);
            // return (<h3>Loading</h3>)
        }
    }
    // 教育大市集 edu_market
    else if (group_id === '3') {

        let total_resource = 0;
        let total_use = 0;
        if (typeof formContent.total_resource !== "undefined") {
            total_resource = Number(formContent.total_resource.app_launched) + Number(formContent.total_resource.web_launched) + Number(formContent.total_resource.e_book_launched);
        } // if

        if (typeof formContent.resource_usage !== "undefined") {
            total_use = Number(formContent.resource_usage.referenced_API_count) + Number(formContent.resource_usage.downloads_count) + Number(formContent.resource_usage.shared_video_count);
        } // if

        if (isLoaded) {
            return (
                <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }} >
                    <Card.Body >
                        <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }}>
                            <Container>
                                {loading && !isEditing ? < small style={{ color: 'red' }}>正在上傳...</small> : ''}
                                {!canEdit ? < small style={{ color: 'red' }}>載入中，請稍後...</small> : ''}
                                {<div class="d-grid gap-2 d-md-flex justify-content-md-end"><Button onClick={handleBack}>
                                    回上一頁
                                </Button>
                                </div>}
                                <>{formContent && (
                                    <>{isQuarterlyReport ?
                                        <>
                                            {/* 季報 */}
                                            {quartlyTable()}
                                        </>
                                        :
                                        <>
                                            {/* 月報 */}

                                            <h3 className="text-primary" style={{ textAlign: 'left' }}> {groupName} </h3>
                                            <h5 style={{ textAlign: 'center' }} >{yearTitle} 年 {monthTitle} {reportTitle}資料</h5>
                                            <h5 style={{ fontWeight: 'bold' }}>平臺使用情形</h5>
                                            <h6>說明1：平臺使用情形皆為量化數據。</h6>
                                            <h6>說明2：各式數據千分位數不逗號。</h6>
                                            <h6>說明3：注意單位（時分秒）換算。</h6>
                                            <div style={{ textAlign: 'center' }} >
                                                <Button onClick={handleEditClick} disabled={isEditing} class="btn btn-secondary">編輯</Button>
                                            </div>
                                            <Table striped bordered className="month-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '300px' }}>面項</th>
                                                        <th style={{ width: '500px' }}>填寫內容說明</th>
                                                        <th style={{ width: '100px' }}>樣態</th>
                                                        <th>執行情形</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {/* 【使用面】該月使用人次 */}
                                                    <tr>
                                                        <td>
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            使用人次
                                                        </td>
                                                        <td className="left-align">未登入總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.not_logged_in_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            not_logged_in_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.not_logged_in_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【資源面】平臺資源總數 */}
                                                    <tr>
                                                        <td rowSpan="4">
                                                            【資源面】平臺資源
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>總數</span>
                                                        </td>
                                                        <td className="left-align">APP上架總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.total_resource.app_launched}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        total_resource: {
                                                                            ...formContent.total_resource,
                                                                            app_launched: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.total_resource?.app_launched || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="left-align">WEB上架總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.total_resource.web_launched}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        total_resource: {
                                                                            ...formContent.total_resource,
                                                                            web_launched: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.total_resource?.web_launched || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="left-align">E-BOOK上架總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.total_resource.e_book_launched}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        total_resource: {
                                                                            ...formContent.total_resource,
                                                                            e_book_launched: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.total_resource?.e_book_launched || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="left-align">資源小計</td>
                                                        <td>-</td>
                                                        <td>{total_resource}</td>
                                                    </tr>

                                                    {/* 【使用面】該月平臺資源使用情形 */}
                                                    <tr>
                                                        <td rowSpan="4">
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺資源使用情形
                                                        </td>
                                                        <td className="left-align">該月API被引用次數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_usage.referenced_API_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_usage: {
                                                                            ...formContent.resource_usage,
                                                                            referenced_API_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_usage?.referenced_API_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">該月下載數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_usage.downloads_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_usage: {
                                                                            ...formContent.resource_usage,
                                                                            downloads_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_usage?.downloads_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td className="left-align">該月分享數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_usage.shared_video_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_usage: {
                                                                            ...formContent.resource_usage,
                                                                            shared_video_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_usage?.shared_video_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>使用小計</td>
                                                        <td>-</td>
                                                        <td>{total_use}</td>
                                                    </tr>

                                                    {/* 【資料儲存】計算期間：112年 */}
                                                    <tr>
                                                        <td rowSpan="2">【資料儲存】計算期間：{yearTitle - 1911}年</td>
                                                        <td className="left-align">該月上傳eduLRS筆數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_storage.eduLRS_month_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_storage: {
                                                                            ...formContent.resource_storage,
                                                                            eduLRS_month_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_storage?.eduLRS_month_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">累計上傳eduLRS筆數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_storage.eduLRS_total_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_storage: {
                                                                            ...formContent.resource_storage,
                                                                            eduLRS_total_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_storage?.eduLRS_total_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【GA4分析】該月平臺使用情形 */}
                                                    <tr>
                                                        <td rowSpan="10">
                                                            【GA4分析】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺使用情形
                                                        </td>
                                                        <td className="left-align">使用者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.user}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            user: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.user || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">新使用者人數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.new_users}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            new_users: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.new_users || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">回訪者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.revisits}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            revisits: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.revisits || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-桌機desktop</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.desktops}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            desktops: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.desktops || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-行動裝置mobile</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.mobiles}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            mobiles: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.mobiles || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-平板電腦tablet</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.tablets}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            tablets: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.tablets || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">每位使用者互動工作階段</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.interactive_work_phase}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            interactive_work_phase: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.interactive_work_phase || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均單次工作階段參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.work_phase_average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            work_phase_average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.work_phase_average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">瀏覽</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.views}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            views: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.views || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>


                                                </tbody>
                                            </Table>


                                            {isEditing && (
                                                <div style={{ textAlign: 'center' }} >
                                                    <Button onClick={() => handleSaveClick()}>儲存</Button>
                                                </div>
                                            )}

                                        </>
                                    }
                                    </>
                                )
                                }</>
                            </Container>
                        </Card>
                    </Card.Body>
                </Card>

            )
        }
        else {
            navigate("/selectTypeScreen/" + group_id);
            // return (<h3>Loading</h3>)
        }
    }
    // 教育百科
    else if (group_id === '4') {

        let total_use = 0;
        if (typeof formContent.resource_usage !== "undefined") {
            total_use = Number(formContent.resource_usage.referenced_API_count) + Number(formContent.resource_usage.forwarded_count);
        } // if

        if (isLoaded) {
            return (
                <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }} >
                    <Card.Body >
                        <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }}>
                            <Container>
                                {loading && !isEditing ? < small style={{ color: 'red' }}>正在上傳...</small> : ''}
                                {!canEdit ? < small style={{ color: 'red' }}>載入中，請稍後...</small> : ''}
                                {<div class="d-grid gap-2 d-md-flex justify-content-md-end"><Button onClick={handleBack}>
                                    回上一頁
                                </Button>
                                </div>}
                                <>{formContent && (
                                    <>{isQuarterlyReport ?
                                        <>
                                            {/* 季報 */}
                                            {quartlyTable()}
                                        </>
                                        :
                                        <>
                                            {/* 月報 */}

                                            <h3 className="text-primary" style={{ textAlign: 'left' }}> {groupName} </h3>
                                            <h5 style={{ textAlign: 'center' }} >{yearTitle} 年 {monthTitle} {reportTitle}資料</h5>
                                            <h5 style={{ fontWeight: 'bold' }}>平臺使用情形</h5>
                                            <h6>說明1：平臺使用情形皆為量化數據。</h6>
                                            <h6>說明2：各式數據千分位數不逗號。</h6>
                                            <h6>說明3：注意單位（時分秒）換算。</h6>
                                            <div style={{ textAlign: 'center' }} >
                                                <Button onClick={handleEditClick} disabled={isEditing} class="btn btn-secondary">編輯</Button>
                                            </div>
                                            <Table striped bordered className="month-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '300px' }}>面項</th>
                                                        <th style={{ width: '400px' }}>填寫內容說明</th>
                                                        <th style={{ width: '100px' }}>樣態</th>
                                                        <th style={{ width: '400px' }}>執行情形</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {/* 【使用面】該月使用人次 */}
                                                    <tr>
                                                        <td>
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            使用人次
                                                        </td>
                                                        <td className="left-align">未登入總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.not_logged_in_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            not_logged_in_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.not_logged_in_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【資源面】平臺資源總數 */}
                                                    <tr>
                                                        <td>
                                                            【資源面】平臺資源
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>總數</span>
                                                        </td>
                                                        <td className="left-align">詞條總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.total_resource.total_entries}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        total_resource: {
                                                                            ...formContent.total_resource,
                                                                            total_entries: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.total_resource?.total_entries || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                    {/* 【使用面】該月平臺資源使用情形 */}
                                                    <tr>
                                                        <td rowSpan="3">
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺資源使用情形
                                                        </td>
                                                        <td className="left-align">該月API被引用次數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_usage.referenced_API_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_usage: {
                                                                            ...formContent.resource_usage,
                                                                            referenced_API_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_usage?.referenced_API_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">該月轉寄數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_usage.forwarded_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_usage: {
                                                                            ...formContent.resource_usage,
                                                                            forwarded_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_usage?.forwarded_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用小計</td>
                                                        <td>-</td>
                                                        <td>{total_use}</td>
                                                    </tr>

                                                    {/* 【資料儲存】計算期間：112年 */}
                                                    <tr>
                                                        <td rowSpan="2">【資料儲存】計算期間：{yearTitle - 1911}年</td>
                                                        <td className="left-align">該月上傳eduLRS筆數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_storage.eduLRS_month_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_storage: {
                                                                            ...formContent.resource_storage,
                                                                            eduLRS_month_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_storage?.eduLRS_month_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">累計上傳eduLRS筆數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_storage.eduLRS_total_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_storage: {
                                                                            ...formContent.resource_storage,
                                                                            eduLRS_total_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_storage?.eduLRS_total_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>


                                                    {/* 【GA4分析】該月平臺使用情形 */}
                                                    <tr>
                                                        <td rowSpan="10">
                                                            【GA4分析】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺使用情形
                                                        </td>
                                                        <td className="left-align">使用者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.user}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            user: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.user || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">新使用者人數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.new_users}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            new_users: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.new_users || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">回訪者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.revisits}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            revisits: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.revisits || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-桌機desktop</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.desktops}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            desktops: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />)
                                                                : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.desktops || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-行動裝置mobile</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.mobiles}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            mobiles: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.mobiles || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-平板電腦tablet</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.tablets}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            tablets: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.tablets || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="left-align">每位使用者互動工作階段</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.interactive_work_phase}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            interactive_work_phase: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.interactive_work_phase || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均單次工作階段參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.work_phase_average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            work_phase_average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.work_phase_average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">瀏覽</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.views}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            views: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.views || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            {isEditing && (
                                                <div style={{ textAlign: 'center' }} >
                                                    <Button onClick={() => handleSaveClick()}>儲存</Button>
                                                </div>
                                            )}

                                        </>
                                    }
                                    </>
                                )
                                }</>
                            </Container>
                        </Card>
                    </Card.Body>
                </Card>

            )
        }
        else {
            navigate("/selectTypeScreen/" + group_id);
            // return (<h3>Loading</h3>)
        }
    }
    // 教育媒體影音
    else if (group_id === '5') {
        let total_use = 0;

        if (typeof formContent.resource_usage !== "undefined") {
            total_use = Number(formContent.resource_usage.referenced_API_count) + Number(formContent.resource_usage.shared_video_count) + Number(formContent.resource_usage.watched_count);
        } // if

        if (isLoaded) {
            return (
                <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }} >
                    <Card.Body>
                        <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }}>
                            <Container>
                                {loading && !isEditing ? < small style={{ color: 'red' }}>正在上傳...</small> : ''}
                                {!canEdit ? < small style={{ color: 'red' }}>載入中，請稍後...</small> : ''}
                                {<div class="d-grid gap-2 d-md-flex justify-content-md-end"><Button onClick={handleBack}>
                                    回上一頁
                                </Button>
                                </div>}
                                <>{formContent && (
                                    <>{isQuarterlyReport ?
                                        <>
                                            {/* 季報 */}
                                            {quartlyTable()}
                                        </>
                                        :
                                        <>
                                            {/* 月報 */}

                                            <h3 className="text-primary" style={{ textAlign: 'left' }}> {groupName} </h3>
                                            <h5 style={{ textAlign: 'center' }} >{yearTitle} 年 {monthTitle} {reportTitle}資料</h5>
                                            <h5 style={{ fontWeight: 'bold' }}>平臺使用情形</h5>
                                            <h6>說明1：平臺使用情形皆為量化數據。</h6>
                                            <h6>說明2：各式數據千分位數不逗號。</h6>
                                            <h6>說明3：注意單位（時分秒）換算。</h6>
                                            <div style={{ textAlign: 'center' }} >
                                                <Button onClick={handleEditClick} disabled={isEditing} class="btn btn-secondary">編輯</Button>
                                            </div>
                                            <Table striped bordered className="month-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '300px' }}>面項</th>
                                                        <th style={{ width: '400px' }}>填寫內容說明</th>
                                                        <th style={{ width: '100px' }}>樣態</th>
                                                        <th style={{ width: '400px' }}>執行情形</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {/* 【使用面】該月使用人次 */}
                                                    <tr>
                                                        <td>
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            使用人次
                                                        </td>
                                                        <td className="left-align">未登入總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.not_logged_in_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            not_logged_in_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.not_logged_in_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【資源面】平臺資源總數 */}
                                                    <tr>
                                                        <td>【
                                                            資源面】平臺資源
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>總數</span>
                                                        </td>
                                                        <td className="left-align">影片總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.total_resource.total_entries}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        total_resource: {
                                                                            ...formContent.total_resource,
                                                                            total_entries: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.total_resource?.total_entries || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                    {/* 【使用面】該月平臺資源使用情形 */}
                                                    <tr>
                                                        <td rowSpan="4">
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺資源使用情形
                                                        </td>
                                                        <td className="left-align">該月API被引用次數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_usage.referenced_API_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_usage: {
                                                                            ...formContent.resource_usage,
                                                                            referenced_API_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_usage?.referenced_API_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">該月分享數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_usage.shared_video_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_usage: {
                                                                            ...formContent.resource_usage,
                                                                            shared_video_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_usage?.shared_video_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">該月影片被觀看次數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_usage.watched_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_usage: {
                                                                            ...formContent.resource_usage,
                                                                            watched_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_usage?.watched_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用小計</td>
                                                        <td>-</td>
                                                        <td>{total_use}</td>
                                                    </tr>


                                                    {/* 【使用面】該月平臺資源-影片使用情形 */}
                                                    <tr>
                                                        <td>
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺資源-影片使用情形
                                                        </td>
                                                        <td className="left-align">該月影片被觀看時間（單位:時）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_usage.watched_time_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_usage: {
                                                                            ...formContent.resource_usage,
                                                                            watched_time_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_usage?.watched_time_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【資料儲存】計算期間：112年 */}
                                                    <tr>
                                                        <td rowSpan="2">【資料儲存】計算期間：{yearTitle - 1911}年</td>
                                                        <td className="left-align">該月上傳eduLRS筆數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_storage.eduLRS_month_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_storage: {
                                                                            ...formContent.resource_storage,
                                                                            eduLRS_month_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_storage?.eduLRS_month_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">累計上傳eduLRS筆數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.resource_storage.eduLRS_total_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        resource_storage: {
                                                                            ...formContent.resource_storage,
                                                                            eduLRS_total_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.resource_storage?.eduLRS_total_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>


                                                    {/* 【GA4分析】該月平臺使用情形 */}
                                                    <tr>
                                                        <td rowSpan="10">
                                                            【GA4分析】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺使用情形
                                                        </td>
                                                        <td className="left-align">使用者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.user}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            user: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.user || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">新使用者人數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.new_users}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            new_users: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.new_users || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">回訪者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.revisits}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            revisits: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.revisits || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-桌機desktop</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.desktops}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            desktops: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />)
                                                                : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.desktops || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-行動裝置mobile</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.mobiles}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            mobiles: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.mobiles || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-平板電腦tablet</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.tablets}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            tablets: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.tablets || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="left-align">每位使用者互動工作階段</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.interactive_work_phase}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            interactive_work_phase: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.interactive_work_phase || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均單次工作階段參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.work_phase_average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            work_phase_average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.work_phase_average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">瀏覽</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.views}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            views: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) : (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.views || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            {isEditing && (
                                                <div style={{ textAlign: 'center' }} >
                                                    <Button onClick={() => handleSaveClick()}>儲存</Button>
                                                </div>
                                            )}

                                        </>
                                    }
                                    </>
                                )
                                }</>
                            </Container>
                        </Card>
                    </Card.Body>
                </Card>
            )
        }
        else {
            navigate("/selectTypeScreen/" + group_id);
            // return (<h3>Loading</h3>)
        }
    }
    // 教育雲入口網
    else if (group_id === '6') {
        if (isLoaded) {
            return (
                <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }} >
                    <Card.Body >
                        <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }}>
                            <Container>
                                {loading && !isEditing ? < small style={{ color: 'red' }}>正在上傳...</small> : ''}
                                {!canEdit ? < small style={{ color: 'red' }}>載入中，請稍後...</small> : ''}
                                {<div class="d-grid gap-2 d-md-flex justify-content-md-end"><Button onClick={handleBack}>
                                    回上一頁
                                </Button>
                                </div>}
                                <>{formContent && (
                                    <>{isQuarterlyReport ?
                                        <>
                                            {/* 季報 */}
                                            {quartlyTable()}
                                        </>
                                        :
                                        <>
                                            {/* 月報 */}

                                            <h3 className="text-primary" style={{ textAlign: 'left' }}> {groupName} </h3>
                                            <h5 style={{ textAlign: 'center' }} >{yearTitle} 年 {monthTitle} {reportTitle}資料</h5>
                                            <h5 style={{ fontWeight: 'bold' }}>平臺使用情形</h5>
                                            <h6>說明1：平臺使用情形皆為量化數據。</h6>
                                            <h6>說明2：各式數據千分位數不逗號。</h6>
                                            <h6>說明3：注意單位（時分秒）換算。</h6>

                                            <div style={{ textAlign: 'center' }} >
                                                <Button onClick={handleEditClick} disabled={isEditing} class="btn btn-secondary">編輯</Button>
                                            </div>
                                            <Table striped bordered className="month-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '300px' }}>面項</th>
                                                        <th style={{ width: '400px' }}>填寫內容說明</th>
                                                        <th style={{ width: '100px' }}>樣態</th>
                                                        <th style={{ width: '400px' }}>執行情形</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {/* 【使用面】該月使用人次 */}
                                                    <tr>
                                                        <td>
                                                            【使用面】
                                                            該月<span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            使用人次
                                                        </td>
                                                        <td className="left-align">未登入總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.not_logged_in_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            not_logged_in_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.not_logged_in_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【資源面】平臺資源總數 */}
                                                    <tr>
                                                        <td>
                                                            【資源面】平臺資源
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>總數</span>
                                                        </td>
                                                        <td className="left-align">策展文章（含教學應用、好物推薦）總篇數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.total_resource.favorite_website_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        total_resource: {
                                                                            ...formContent.total_resource,
                                                                            favorite_website_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.total_resource?.favorite_website_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【使用面】平臺資源使用總數 */}
                                                    <tr>
                                                        <td>
                                                            【使用面】平臺資源使用
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>總數</span>
                                                        </td>
                                                        <td className="left-align">策展文章累計點擊總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.platform_resource_usage.curated_article_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        platform_resource_usage: {
                                                                            ...formContent.platform_resource_usage,
                                                                            curated_article_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.platform_resource_usage?.curated_article_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>


                                                    {/* 【GA4分析】該月平臺使用情形 */}
                                                    <tr>
                                                        <td rowSpan="10">
                                                            【GA4分析】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺使用情形
                                                        </td>
                                                        <td className="left-align">使用者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.user}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            user: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.user || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">新使用者人數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.new_users}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            new_users: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.new_users || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">回訪者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.revisits}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            revisits: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.revisits || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-桌機desktop</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.desktops}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            desktops: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.desktops || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-行動裝置mobile</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.mobiles}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            mobiles: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.mobiles || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-平板電腦tablet</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.tablets}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            tablets: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.tablets || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">每位使用者互動工作階段</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.interactive_work_phase}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            interactive_work_phase: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.interactive_work_phase || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均單次工作階段參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.work_phase_average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            work_phase_average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.work_phase_average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">瀏覽</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.views}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            views: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.views || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                            {isEditing && (
                                                <div style={{ textAlign: 'center' }} >
                                                    <Button onClick={() => handleSaveClick()}>儲存</Button>
                                                </div>
                                            )}

                                        </>
                                    }
                                    </>
                                )
                                }</>
                            </Container>
                        </Card>
                    </Card.Body>
                </Card>

            )
        }
        else {
            navigate("/selectTypeScreen/" + group_id);
            // return (<h3>Loading</h3>)
        }
    }
    // IDC 教育雲帳號與雲端資料中心
    else if (group_id === '7') {

        // 教師人次小計
        let total_teacher_times = 0;
        // 學生人次小計
        let total_std_times = 0;
        // 教師人數小計
        let total_teacher_count = 0;
        // 學生人數小計
        let total_std_count = 0;

        if (typeof formContent.monthly_usage !== "undefined") {

            total_teacher_times = Number(formContent.monthly_usage.edu_learning_entrance_teacher_login_times) + Number(formContent.monthly_usage.edu_entrance_teacher_login_times)
                + Number(formContent.monthly_usage.edu_ADL_teacher_login_times) + Number(formContent.monthly_usage.edu_market_teacher_login_times)
                + Number(formContent.monthly_usage.edu_pedia_teacher_login_times) + Number(formContent.monthly_usage.edu_video_teacher_login_times)
                + Number(formContent.monthly_usage.edu_moocs_teacher_login_times) + Number(formContent.monthly_usage.school_cloud_mail_teacher_login_times);

            total_std_times = Number(formContent.monthly_usage.edu_learning_entrance_student_login_times) + Number(formContent.monthly_usage.edu_entrance_student_login_times)
                + Number(formContent.monthly_usage.edu_ADL_student_login_times) + Number(formContent.monthly_usage.edu_market_student_login_times)
                + Number(formContent.monthly_usage.edu_pedia_student_login_times) + Number(formContent.monthly_usage.edu_video_student_login_times)
                + Number(formContent.monthly_usage.edu_moocs_student_login_times) + Number(formContent.monthly_usage.school_cloud_mail_student_login_times);

            total_teacher_count = Number(formContent.monthly_usage.edu_learning_entrance_teacher_login_count) + Number(formContent.monthly_usage.edu_entrance_teacher_login_count)
                + Number(formContent.monthly_usage.edu_ADL_teacher_login_count) + Number(formContent.monthly_usage.edu_market_teacher_login_count)
                + Number(formContent.monthly_usage.edu_pedia_teacher_login_count) + Number(formContent.monthly_usage.edu_video_teacher_login_count)
                + Number(formContent.monthly_usage.edu_moocs_teacher_login_count) + Number(formContent.monthly_usage.school_cloud_mail_teacher_login_count);

            total_std_count = Number(formContent.monthly_usage.edu_learning_entrance_student_login_count) + Number(formContent.monthly_usage.edu_entrance_student_login_count)
                + Number(formContent.monthly_usage.edu_ADL_student_login_count) + Number(formContent.monthly_usage.edu_market_student_login_count)
                + Number(formContent.monthly_usage.edu_pedia_student_login_count) + Number(formContent.monthly_usage.edu_video_student_login_count)
                + Number(formContent.monthly_usage.edu_moocs_student_login_count) + Number(formContent.monthly_usage.school_cloud_mail_student_login_count);

        } // if 

        // 資源小計
        let total_resource = 0;
        if (typeof formContent.platform_resource !== "undefined") {
            total_resource = Number(formContent.platform_resource.video_count) + Number(formContent.platform_resource.test_problem_count) + Number(formContent.platform_resource.interactive_element_count) + Number(formContent.platform_resource.dynamic_scoring_element_count);
        } // if 


        if (isLoaded) {
            return (
                <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }} >
                    <Card.Body >
                        <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }}>
                            <Container>

                                {loading && !isEditing ? < small style={{ color: 'red' }}>正在上傳...</small> : ''}
                                {!canEdit ? < small style={{ color: 'red' }}>載入中，請稍後...</small> : ''}
                                {<div class="d-grid gap-2 d-md-flex justify-content-md-end"><Button onClick={handleBack}>
                                    回上一頁
                                </Button>
                                </div>}
                                <>{formContent && (
                                    <>{isQuarterlyReport ?
                                        <>
                                            {/* 季報 */}
                                            {quartlyTable()}
                                        </>
                                        :
                                        <>
                                            {/* 月報 */}

                                            <h3 className="text-primary" style={{ textAlign: 'left' }}> {groupName} </h3>
                                            <h5 style={{ textAlign: 'center' }} >{yearTitle} 年 {monthTitle} {reportTitle}資料</h5>
                                            <h5 style={{ fontWeight: 'bold' }}>帳號介接情形</h5>
                                            <h6>說明1：帳號介接情形為量化數據。</h6>
                                            <h6>說明2：各式數據千分位數不逗號。</h6>
                                            <div style={{ textAlign: 'center' }} >
                                                <Button onClick={handleEditClick} disabled={isEditing} class="btn btn-secondary">編輯</Button>
                                            </div>
                                            <Table striped bordered className="month-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '300px' }}>面項</th>
                                                        <th style={{ width: '400px' }}>填寫內容說明</th>
                                                        <th style={{ width: '100px' }}>樣態</th>
                                                        <th style={{ width: '400px' }}>執行情形</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {/* 【資源面】帳號介接總數*/}
                                                    <tr>
                                                        <td>
                                                            【資源面】帳號介接
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>總數</span>
                                                        </td>
                                                        <td className="left-align">介接系統/平臺/服務總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.account_interface.count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        account_interface: {
                                                                            ...formContent.account_interface,
                                                                            count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.account_interface?.count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【使用面】該月使用人次與人數*/}
                                                    <tr>
                                                        <td rowSpan="2">
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            使用人次與人數
                                                        </td>
                                                        <td className="left-align">介接所有系統/平臺/服務總人次</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.person_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            person_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.person_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">介接所有系統/平臺/服務總人數</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.people_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            people_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.people_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    {/* 【使用面】該月使用人次與人數*/}
                                                    <tr>
                                                        <td rowSpan="36">
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            使用人次與人數
                                                        </td>
                                                        <td className="left-align">
                                                            <div>教育雲數位學習入口網「教師登入次數」</div>
                                                            <div>（https://elearning.cloud.edu.tw/）</div>
                                                        </td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_learning_entrance_teacher_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_learning_entrance_teacher_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_learning_entrance_teacher_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">
                                                            <div>教育雲數位學習入口網「學生登入次數」</div>
                                                            <div>（https://elearning.cloud.edu.tw/）</div>
                                                        </td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_learning_entrance_student_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_learning_entrance_student_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_learning_entrance_student_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">
                                                            <div>教育雲入口網「教師登入次數」</div>
                                                            <div>（https://cloud.edu.tw/）</div>
                                                        </td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_entrance_teacher_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_entrance_teacher_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_entrance_teacher_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">
                                                            <div>教育雲入口網「學生登入次數」</div>
                                                            <div>（https://cloud.edu.tw/）</div>
                                                        </td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_entrance_student_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_entrance_student_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_entrance_student_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育部因材網「教師登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_ADL_teacher_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_ADL_teacher_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_ADL_teacher_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育部因材網「學生登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_ADL_student_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_ADL_student_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_ADL_student_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育大市集「教師登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_market_teacher_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_market_teacher_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_market_teacher_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育大市集「學生登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_market_student_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_market_student_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_market_student_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育百科「教師登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_pedia_teacher_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_pedia_teacher_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_pedia_teacher_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育百科「學生登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_pedia_student_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_pedia_student_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_pedia_student_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育媒體影音「教師登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_video_teacher_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_video_teacher_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_video_teacher_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育媒體影音「學生登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_video_student_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_video_student_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_video_student_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">edu磨課師+「教師登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_moocs_teacher_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_moocs_teacher_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_moocs_teacher_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">edu磨課師+「學生登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_moocs_student_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_moocs_student_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_moocs_student_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">校園雲端電子郵件「教師登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.school_cloud_mail_teacher_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            school_cloud_mail_teacher_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.school_cloud_mail_teacher_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">校園雲端電子郵件「學生登入次數」</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.school_cloud_mail_student_login_times}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            school_cloud_mail_student_login_times: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.school_cloud_mail_student_login_times || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">
                                                            <div>教育雲數位學習入口網「教師登入人數」</div>
                                                            <div>（https://elearning.cloud.edu.tw/）</div>
                                                        </td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_learning_entrance_teacher_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_learning_entrance_teacher_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_learning_entrance_teacher_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">
                                                            <div>教育雲數位學習入口網「學生登入人數」</div>
                                                            <div>（https://elearning.cloud.edu.tw/）</div>
                                                        </td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_learning_entrance_student_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_learning_entrance_student_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_learning_entrance_student_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">
                                                            <div>教育雲入口網「教師登入人數」</div>
                                                            <div>（https://cloud.edu.tw/）</div>
                                                        </td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_entrance_teacher_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_entrance_teacher_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_entrance_teacher_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">
                                                            <div>教育雲入口網「學生登入人數」</div>
                                                            <div>（https://cloud.edu.tw/）</div>
                                                        </td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_entrance_student_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_entrance_student_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_entrance_student_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育部因材網「教師登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_ADL_teacher_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_ADL_teacher_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_ADL_teacher_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育部因材網「學生登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_ADL_student_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_ADL_student_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_ADL_student_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育大市集「教師登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_market_teacher_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_market_teacher_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_market_teacher_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育大市集「學生登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_market_student_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_market_student_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_market_student_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育百科「教師登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_pedia_teacher_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_pedia_teacher_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_pedia_teacher_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育百科「學生登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_pedia_student_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_pedia_student_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_pedia_student_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育媒體影音「教師登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_video_teacher_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_video_teacher_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_video_teacher_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">教育媒體影音「學生登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_video_student_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_video_student_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_video_student_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">edu磨課師+「教師登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_moocs_teacher_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_moocs_teacher_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_moocs_teacher_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">edu磨課師+「學生登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_moocs_student_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_moocs_student_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_moocs_student_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">校園雲端電子郵件「教師登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.school_cloud_mail_teacher_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            school_cloud_mail_teacher_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.school_cloud_mail_teacher_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">校園雲端電子郵件「學生登入人數」</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.school_cloud_mail_student_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            school_cloud_mail_student_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.school_cloud_mail_student_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>教師人次小計</td>
                                                        <td>-</td>
                                                        <td>{total_teacher_times}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>學生人次小計</td>
                                                        <td>-</td>
                                                        <td>{total_std_times}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>教師人數小計</td>
                                                        <td>-</td>
                                                        <td>{total_teacher_count}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>學生人數小計</td>
                                                        <td>-</td>
                                                        <td>{total_std_count}</td>
                                                    </tr>

                                                </tbody>


                                            </Table>


                                            {isEditing && (
                                                <div style={{ textAlign: 'center' }} >
                                                    <Button onClick={() => handleSaveClick()}>儲存</Button>
                                                </div>
                                            )}

                                            {/* 呈現下一個表格前放空行比較清楚 */}
                                            <br /><br /><br />

                                            <h5 style={{ fontWeight: 'bold' }}>服務內容概要</h5>
                                            <h6>說明：服務內容概要為質化說明。</h6>
                                            <div style={{ textAlign: 'center' }} >
                                                <Button onClick={handleEditClickTable2} disabled={isEditingTable2} class="btn btn-secondary">編輯</Button>
                                            </div>
                                            <Table striped bordered className="month-table">
                                                <thead>
                                                    <th style={{ width: '300px' }}>面項</th>
                                                    <th style={{ width: '400px' }} >填寫內容說明</th>
                                                    <th style={{ width: '100px' }}>樣態</th>
                                                    <th style={{ width: '400px' }}>內容</th>
                                                </thead>
                                                <tbody>
                                                    {/* 【使用面】教育雲端帳號 */}
                                                    <tr>
                                                        <td>【使用面】教育雲端帳號</td>
                                                        <td>強化教育體系單一簽入服務系統穩定性及可用性</td>
                                                        <td>質化</td>
                                                        <td>
                                                            {isEditingTable2 ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.edu_cloud_account}
                                                                onChange={(e) => {
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        edu_cloud_account: e.target.value
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '160px', overflow: 'auto' }}>{formContent.edu_cloud_account ? formContent.edu_cloud_account : ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>


                                                    {/*【使用面】雲端資料中心  */}
                                                    <tr>
                                                        <td>【使用面】雲端資料中心</td>
                                                        <td>強化教育雲網路資通安全防護，提升雲端基礎服務效能</td>
                                                        <td>質化</td>
                                                        <td>
                                                            {isEditingTable2 ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.cloud_data_center}
                                                                onChange={(e) => {
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        cloud_data_center: e.target.value
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '160px', overflow: 'auto' }}>{formContent.cloud_data_center ? formContent.cloud_data_center : ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                            {isEditingTable2 && (
                                                <div style={{ textAlign: 'center' }} >
                                                    <Button onClick={() => handleSaveClickTable2()}>儲存</Button>
                                                </div>
                                            )}

                                        </>
                                    }
                                    </>
                                )
                                }</>

                            </Container>
                        </Card>
                    </Card.Body>
                </Card>
            )
        }
        else {
            navigate("/selectTypeScreen/" + group_id);
            // return (<h3>Loading</h3>)
        }

    }
    // 數位學伴
    else if (group_id === '8') {
        if (isLoaded) {
            return (
                <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }} >
                    <Card.Body >
                        <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }}>
                            <Container>
                                {loading && !isEditing ? < small style={{ color: 'red' }}>正在上傳...</small> : ''}
                                {!canEdit ? < small style={{ color: 'red' }}>載入中，請稍後...</small> : ''}
                                {<div class="d-grid gap-2 d-md-flex justify-content-md-end"><Button onClick={handleBack}>
                                    回上一頁
                                </Button>
                                </div>}
                                <>{formContent && (
                                    <>{isQuarterlyReport ?
                                        <>
                                            {/* 季報 */}
                                            {quartlyTable()}
                                        </>
                                        :
                                        <>
                                            {/* 月報 */}

                                            <h3 className="text-primary" style={{ textAlign: 'left' }}> {groupName} </h3>
                                            <h5 style={{ textAlign: 'center' }} >{yearTitle} 年 {monthTitle} {reportTitle}資料</h5>
                                            <h5 style={{ fontWeight: 'bold' }}>平臺使用情形</h5>
                                            <h6>說明1：平臺使用情形皆為量化數據。</h6>
                                            <h6>說明2：各式數據千分位數不逗號。</h6>
                                            <h6>說明3：注意單位（時分秒）換算。</h6>

                                            <div style={{ textAlign: 'center' }} >
                                                <Button onClick={handleEditClick} disabled={isEditing} class="btn btn-secondary">編輯</Button>
                                            </div>
                                            <Table striped bordered className="month-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '300px' }}>面項</th>
                                                        <th style={{ width: '400px' }}>填寫內容說明</th>
                                                        <th style={{ width: '100px' }}>樣態</th>
                                                        <th style={{ width: '400px' }}>執行情形</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {/* 【GA4分析】該月平臺使用情形 */}
                                                    <tr>
                                                        <td rowSpan="10">
                                                            【GA4分析】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺使用情形
                                                        </td>
                                                        <td className="left-align">使用者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.user}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            user: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.user || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">新使用者人數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.new_users}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            new_users: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.new_users || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">回訪者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.revisits}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            revisits: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.revisits || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-桌機desktop</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.desktops}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            desktops: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.desktops || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-行動裝置mobile</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.mobiles}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            mobiles: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.mobiles || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-平板電腦tablet</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.tablets}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            tablets: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.tablets || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">每位使用者互動工作階段</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.interactive_work_phase}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            interactive_work_phase: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.interactive_work_phase || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均單次工作階段參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.work_phase_average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            work_phase_average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.work_phase_average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">瀏覽</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.views}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            views: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.views || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>



                                            {isEditing && (
                                                <div style={{ textAlign: 'center' }} >
                                                    <Button onClick={() => handleSaveClick()}>儲存</Button>
                                                </div>
                                            )}

                                        </>
                                    }
                                    </>
                                )
                                }</>

                            </Container>
                        </Card>
                    </Card.Body>
                </Card>
            )
        }
        else {
            navigate("/selectTypeScreen/" + group_id);
            // return (<h3>Loading</h3>)
        }
    }
    // 數位學習入口網
    else if (group_id === '9') {
        if (isLoaded && show_form) {
            return (
                <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }} >
                    <Card.Body >
                        <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }}>
                            <Container>
                                {loading && !isEditing ? < small style={{ color: 'red' }}>正在上傳...</small> : ''}
                                {!canEdit ? < small style={{ color: 'red' }}>載入中，請稍後...</small> : ''}
                                {<div class="d-grid justify-content-md-end"><Button onClick={handleBack}>
                                    回上一頁
                                </Button>
                                </div>}
                                <>{formContent && (
                                    <>{isQuarterlyReport ?
                                        <>
                                            {/* 季報 */}
                                            {quartlyTable()}
                                        </>
                                        :
                                        <>
                                            {/* 月報 */}

                                            <h3 className="text-primary" style={{ textAlign: 'left' }}> {groupName} </h3>
                                            <h5 style={{ textAlign: 'center' }} >{yearTitle} 年 {monthTitle} {reportTitle}資料</h5>
                                            <h5 style={{ fontWeight: 'bold' }}>平臺使用情形</h5>
                                            <h6>說明1：平臺使用情形皆為量化數據。</h6>
                                            <h6>說明2：各式數據千分位數不逗號。</h6>
                                            <h6>說明3：注意單位（時分秒）換算。</h6>
                                            <div style={{ textAlign: 'center' }} >
                                                <Button onClick={handleEditClick} disabled={isEditing} class="btn btn-secondary">編輯</Button>
                                            </div>
                                            <Table striped bordered className="month-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '300px' }}>面項</th>
                                                        <th style={{ width: '400px' }}>填寫內容說明</th>
                                                        <th style={{ width: '100px' }}>樣態</th>
                                                        <th style={{ width: '400px' }}>執行情形</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {/* 【使用面】該月使用人次 */}
                                                    <tr>
                                                        <td>
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            使用人次
                                                        </td>
                                                        <td className="left-align">未登入總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.not_logged_in_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            not_logged_in_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.not_logged_in_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td rowSpan="2">
                                                            【資源面】平臺資源
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>總數</span>
                                                        </td>
                                                        <td className="left-align">收錄網站總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.total_resource.favorite_website_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        total_resource: {
                                                                            ...formContent.total_resource,
                                                                            favorite_website_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.total_resource?.favorite_website_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>策展文章（含教學應用、好物推薦）總篇數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.total_resource.curated_article_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        total_resource: {
                                                                            ...formContent.total_resource,
                                                                            curated_article_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.total_resource?.curated_article_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>


                                                    {/* 【使用面】平臺資源使用總數 */}
                                                    <tr>
                                                        <td>
                                                            【使用面】平臺資源使用
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>總數</span>
                                                        </td>
                                                        <td className="left-align">策展文章累計點擊總數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.platform_resource_usage.curated_article_clicks}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        platform_resource_usage: {
                                                                            ...formContent.platform_resource_usage,
                                                                            curated_article_clicks: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.platform_resource_usage?.curated_article_clicks || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>


                                                    {/* 【GA4分析】該月平臺使用情形 */}
                                                    <tr>
                                                        <td rowSpan="10">
                                                            【GA4分析】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            平臺使用情形
                                                        </td>
                                                        <td className="left-align">使用者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.user}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            user: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.user || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">新使用者人數</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.new_users}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            new_users: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.new_users || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">回訪者</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.revisits}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            revisits: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.revisits || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-桌機desktop</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.desktops}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            desktops: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.desktops || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-行動裝置mobile</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.mobiles}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            mobiles: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.mobiles || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">使用者-平臺/裝置類別-平板電腦tablet</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.tablets}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            tablets: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.tablets || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">每位使用者互動工作階段</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.interactive_work_phase}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            interactive_work_phase: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.interactive_work_phase || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">平均單次工作階段參與時間（單位：秒）</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.work_phase_average_attending_seconds}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            work_phase_average_attending_seconds: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.work_phase_average_attending_seconds || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left-align">瀏覽</td>
                                                        <td>數量</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.GA_analysis.views}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        GA_analysis: {
                                                                            ...formContent.GA_analysis,
                                                                            views: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'pre-line', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.GA_analysis?.views || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                            {isEditing && (
                                                <Button onClick={() => handleSaveClick()}>儲存</Button>
                                            )}

                                        </>
                                    }
                                    </>
                                )
                                }</>
                            </Container>
                        </Card>
                    </Card.Body>
                </Card>
            )
        }
        else {
            navigate("/selectTypeScreen/" + group_id)
            // return (<h3>Loading</h3>)
        }
    }
    else if (group_id === "10") { // 教育雲一般帳號 edu_general

        let total_count = 0;
        let total_times = 0;
        if (typeof formContent.monthly_usage !== "undefined") {
            total_times = Number(formContent.monthly_usage.edu_learning_entrance_login_count) + Number(formContent.monthly_usage.edu_entrance_login_count)
                + Number(formContent.monthly_usage.edu_ADL_login_count) + Number(formContent.monthly_usage.edu_market_login_count)
                + Number(formContent.monthly_usage.edu_pedia_login_count) + Number(formContent.monthly_usage.edu_video_login_count)
                + Number(formContent.monthly_usage.edu_moocs_login_count)

            total_count = Number(formContent.monthly_usage.edu_learning_entrance_login_person_count) + Number(formContent.monthly_usage.edu_entrance_login_person_count)
                + Number(formContent.monthly_usage.edu_ADL_login_person_count) + Number(formContent.monthly_usage.edu_market_login_person_count)
                + Number(formContent.monthly_usage.edu_pedia_login_person_count) + Number(formContent.monthly_usage.edu_video_login_person_person_count)
                + Number(formContent.monthly_usage.edu_moocs_login_person_count)
        } // if 

        if (isLoaded) {
            return (
                // <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center'  }} > 
                // <Card.Body>
                // <Card className="my-4 p-4 d-flex justify-content-center" style={{border: '2px solid #F7F7F7', borderRadius: '10px'}}>
                <Card style={{ width: '100%', marginTop: '20px', display: 'initial', alignItems: 'center' }}>
                    <Card.Body >
                        <Card className="my-4 p-4 d-flex justify-content-center" style={{ border: '2px solid #F7F7F7', borderRadius: '10px' }} >
                            <Container>
                                {loading && !isEditing ? < small style={{ color: 'red' }}>正在上傳...</small> : ''}
                                {!canEdit ? < small style={{ color: 'red' }}>載入中，請稍後...</small> : ''}
                                {<div class="d-grid gap-2 d-md-flex justify-content-md-end"><Button onClick={handleBack}>
                                    回上一頁
                                </Button>
                                </div>}
                                <>{formContent && (
                                    <>{isQuarterlyReport ?
                                        <>
                                            {/* 季報 */}
                                            {quartlyTable()}
                                        </>
                                        :
                                        <>
                                            {/* 月報 */}
                                            <h3 className="text-primary" style={{ textAlign: 'left' }}> {groupName} </h3>
                                            <h5 style={{ textAlign: 'center' }} >{yearTitle} 年 {monthTitle} {reportTitle}資料</h5>
                                            <h5 style={{ fontWeight: 'bold' }}>平臺使用情形</h5>
                                            <h6>說明1：平臺使用情形皆為量化數據。</h6>
                                            <h6>說明2：各式數據千分位數不逗號。</h6>
                                            <h6>說明3：注意單位（時分秒）換算。</h6>
                                            <div style={{ textAlign: 'center' }} >
                                                <Button onClick={handleEditClick} disabled={isEditing} class="btn btn-secondary">編輯</Button>
                                            </div>
                                            <Table striped bordered className="month-table" >
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '300px' }}>面項</th>
                                                        <th style={{ width: '400px' }}>填寫內容說明</th>
                                                        <th style={{ width: '100px' }}>樣態</th>
                                                        <th style={{ width: '400px' }}>執行情形</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {/* 【使用面】該月使用人次與人數 */}
                                                    <tr>
                                                        <td rowSpan="16">
                                                            【使用面】
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>該月</span>
                                                            使用人次與人數
                                                        </td>


                                                        <td className="left">登入「教育雲數位學習入口網」次數</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_learning_entrance_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_learning_entrance_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_learning_entrance_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「教育雲入口網」次數</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_entrance_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_entrance_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_entrance_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「教育部因材網」次數</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_ADL_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_ADL_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_ADL_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「教育大市集」次數</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_market_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_market_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_market_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「教育百科」次數</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_pedia_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_pedia_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_pedia_login_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「教育媒體影音」次數</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_video_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_video_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_video_login_count || ""} </div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「edu磨課師+」次數</td>
                                                        <td>人次</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_moocs_login_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_moocs_login_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_moocs_login_count || ""} </div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「教育雲數位學習入口網」人數</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_learning_entrance_login_person_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_learning_entrance_login_person_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_learning_entrance_login_person_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「教育雲入口網」人數</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_entrance_login_person_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_entrance_login_person_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_entrance_login_person_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「教育部因材網」人數</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_ADL_login_person_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_ADL_login_person_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_ADL_login_person_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「教育大市集」人數</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_market_login_person_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_market_login_person_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_market_login_person_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「教育百科」人數</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_pedia_login_person_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_pedia_login_person_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_pedia_login_person_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「教育媒體影音」人數</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_video_login_person_person_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_video_login_person_person_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_video_login_person_person_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">登入「edu磨課師+」人數</td>
                                                        <td>人數</td>
                                                        <td>
                                                            {isEditing ? (<FormControl
                                                                as="textarea"
                                                                rows={4}
                                                                value={formContent.monthly_usage.edu_moocs_login_person_count}
                                                                onChange={(e) => {
                                                                    const newVal = RestrictNum(e.target.value)
                                                                    setFormContent({
                                                                        ...formContent,
                                                                        monthly_usage: {
                                                                            ...formContent.monthly_usage,
                                                                            edu_moocs_login_person_count: newVal
                                                                        }
                                                                    })
                                                                }}
                                                            />) :
                                                                (<div style={{ whiteSpace: 'normal', width: 'auto', height: '80px', overflow: 'auto' }}>{formContent.monthly_usage?.edu_moocs_login_person_count || ""}</div>)
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">人次小計</td>
                                                        <td>-</td>
                                                        <td>{total_times}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="left">人數小計</td>
                                                        <td>-</td>
                                                        <td>{total_count}</td>
                                                    </tr>

                                                </tbody>
                                            </Table>

                                            {isEditing && (
                                                <div style={{ textAlign: 'center' }} >
                                                    <Button onClick={() => handleSaveClick()} >儲存</Button>
                                                </div>
                                            )}

                                        </>
                                    }
                                    </>
                                )
                                }</>

                            </Container>
                        </Card>
                    </Card.Body>
                </Card>
            )
        }
        else {
            navigate("/selectTypeScreen/" + group_id);
            // return (<h3>Loading</h3>)
        }

    }
    else {
        if (isLoaded) navigate("/selectTypeScreen/" + group_id);
        else navigate("/login");
        // return <>Error</>
    }



    <style jsx>{`
            .month-table th:first-child {
                width: 2%;
                white-space: nowrap;
                vertical-align: middle;
        
            }
            .left-align {
                text-align: left;
            }
            
            .month-table td {
                min-width: 0;
                white-space: nowrap;
            }
            `}</style>
}
export default FormScreen

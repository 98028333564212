export async function login(account, password) {
  const url = `/api/login`;
  // 建立formData物件
  const formData = new FormData(document.querySelector('form'))
  formData.append('account', account)
  formData.append('password', password)

  try {
    var response = await fetch(url, {
      method: 'POST',
      //headers:{
      // 'Content-Type': 'application/form;charset=UTF-8',
      // },
      body: formData
    });
    // console.log(response);
  }
  catch (e) {
    console.log(e);
  }

  const result = await response.json();
  if (result?.token && result?.group) {
    localStorage.setItem('token', result.token);
    localStorage.setItem('group_id', result.group);
  }


  return result;
}

export async function postFormContent(group_id, formDataWithFile) {

  const url = `/api/${group_id}/${
    // formDataWithFile.season == undefined ? 'monthly' : 'seasonly'
    formDataWithFile.season == "" ? 'monthly' : 'seasonly'
    }/uploadUserForm`;
  // 建立formData物件

  // console.log('season: ', formDataWithFile.season)

  try {

    var response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(formDataWithFile)
    });
  }
  catch (e) {
    // console.log(e);
  }

  const res = await response.json();
  // console.log(res)
  return res;
}


export async function getFormContent(group_id, yearTitle, monthTitle, quarterly, reportTitle) {
  // const monthly = {"Jan":1, "Feb":2, "Mar":3, "Apr":4, "May":5, "Jun":6, "Jul":7, "Aug":8, "Sep":9, "Oct":10, "Nov":11, "Dec":12}
  const monthly = { "一月": 1, "二月": 2, "三月": 3, "四月": 4, "五月": 5, "六月": 6, "七月": 7, "八月": 8, "九月": 9, "十月": 10, "十一月": 11, "十二月": 12 }
  const report = { "月報": "monthly", "季報": "seasonly" }
  try {
    const url = reportTitle == "季報" ? `/api/${group_id}/${yearTitle}/${quarterly}/${report[reportTitle]}` : `/api/${group_id}/${yearTitle}/${monthly[monthTitle]}/${report[reportTitle]}`;
    // console.log('url', url);
    const response = await fetch(url)

    const data = await response.json()
    return data
  }
  catch (e) {
    console.log(e);

  }

}

export async function getDashboardData(year) {

  try {
    const url = `/api/kpi?year=${year}`;
    // console.log('url: ', url);
    const requestData = {
      year: year,
    };

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
    const data = await response.json()
    console.log('mimo_api.js: ', data);
    return data
  }
  catch (e) {
    console.log(e);

  }

}

